import { useEffect, useState } from 'react';
import moment from 'moment';

/**
 * @description I added this method since the middleware is not consistent with the format returned. in most cases it returns 24m20s  and omits the hours
 * @param durationString string representation of when the offer should go off i.e  239h29m8.433650901s
 * @returns string 239h29m8.433650901s
 */
export function parseTimeString(durationString: string) {
  const pattern = /^(\d+h)?(\d+m)?(\d+(\.\d+)?s)?$/;
  // If no time components are included, return 0 for hours, minutes, and seconds
  if (!pattern.test(durationString)) {
    return '0h0m0s';
  }
  const matches = durationString.match(pattern);
  const hours = matches?.[1] ? parseInt(matches[1]) : 0;
  const minutes = matches?.[2] ? parseInt(matches[2]) : 0;
  const seconds = matches?.[3] ? parseFloat(matches[3]) : 0;
  return `${hours}h${minutes}m${seconds}s`;
}

/**
 * @description A function to convert durationString to { Date } object
 * @param durationString string representation of when the offer should go off i.e  239h29m8.433650901s
 * @returns { Date } Date object. this is the requivalent in date from current date of the durationString
 */
export function addDurationToDate(durationString: string): Date {
  durationString = parseTimeString(durationString);
  // Split the string into components
  durationString = durationString ?? '0h00m00';
  const components = durationString.split(/[hms]/).filter(Boolean);
  const [hrs, min, sec] = components;

  // Calculate total sec
  const totalsec =
    parseFloat(hrs ?? '0') * 3600 +
    parseFloat(min ?? '0') * 60 +
    parseFloat(sec ?? '0');

  const currentDate = new Date();

  // Calculate the end date and time by adding the duration
  const endDate = new Date(currentDate.getTime() + totalsec * 1000);

  return endDate;
}

interface ICountDownFormat {
  sec: string | null;
  min: string | null;
  hrs: string | null;
  days: string | null;
}

const initial: ICountDownFormat = {
  sec: null,
  min: null,
  hrs: null,
  days: null,
};

/**
 * @description Countdown hook used to display a countdown on the UI
 * @param duration The date representation of the actual duration
 * @param showFull is set to true returns the timer in DD:H:mm:ss else it's H:mm
 * @param callbackFn An optional function that is triggered if timer is done
 * @returns {{ countDownObj, isExpired: isExpired }} where countDownObj is the actual time representation and isExpired is a flag to notify the consumer that the offer is done
 */
export function useCountdown(
  duration: Date,
  showFull: boolean,
  callbackFn?: Function
): { countDownObj: ICountDownFormat; isExpired: boolean } {
  const [isExpired, setIsExpired] = useState(false);
  const [countDownObj, setCountDownObj] = useState(initial);

  useEffect(() => {
    const endDate = duration;
    const interval = setInterval(() => {
      const then = moment(endDate);
      const now = moment();
      const diff = moment.duration(then.diff(now));
      if (then.diff(now) <= 0) {
        clearInterval(interval);
        setIsExpired(true);
        callbackFn?.();
        return;
      }

      const days = Math.floor(diff.asDays()).toString();
      const hrs = showFull
        ? diff.hours().toString()
        : Math.floor(diff.asHours()).toString();
      const min = diff.minutes().toString();
      const sec = diff.seconds().toString();
      setCountDownObj({ days: showFull ? days : '0', hrs, min, sec });
    }, 1000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [duration, showFull, callbackFn]);
  return { countDownObj, isExpired: isExpired };
}
