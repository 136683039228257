'use client';
import clsx from 'clsx';
import { UnlocksIcon } from 'components/icon';
import { useCountdown } from 'hooks/use-countdown';
import React from 'react';

function UnlocksCountdown({
  date,
  containerClassname,
  showTimer = true,
  scale,
}: {
  date: Date;
  containerClassname?: string;
  showTimer?: boolean;
  scale?: boolean;
}) {
  const { countDownObj: timeLeft, isExpired } = useCountdown(date, true);

  const timerComponents = Object.keys(timeLeft).map((interval) => {
    if (!timeLeft[interval as never]) {
      return;
    }
    return (
      <div key={interval}>
        <div className='flex flex-col gap-1 font-semibold'>
          <div className='flex items-center'>
            <div className='flex h-full max-h-7 w-full max-w-7 items-center justify-center rounded-md bg-white text-sm'>
              <span className='p-1.5'>
                {timeLeft[interval as never] < 10 // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  ? `0${timeLeft[interval as never]}`
                  : timeLeft[interval as never]}
              </span>
            </div>
          </div>
          <span className='text-custom-xs font-semibold'>{interval}</span>
        </div>
        {interval !== 'sec' && (
          <span className='mb-5 text-custom-base font-semibold'>:</span>
        )}
      </div>
    );
  });
  return (
    <div
      className={`z-10 flex flex-col items-center gap-3 text-center ${
        isExpired && 'hidden'
      } ${containerClassname!}`}
    >
      <div className='flex h-[50px] w-[50px] items-center justify-center rounded-full bg-white'>
        <UnlocksIcon className='h-[30px] w-[20px]' />
      </div>
      {showTimer && (
        <div
          className={clsx(
            'flex items-center gap-1',
            scale ? 'md:scale-[0.6]' : ''
          )}
        >
          {timerComponents}
        </div>
      )}
    </div>
  );
}

export default UnlocksCountdown;
