export function CalculateGridMultiComponent(currentStyle: string) {
  switch (currentStyle) {
    case '3x4':
      return 'grid-cols-2';
    case '2x4':
      return 'grid-cols-4';
    case '1x4':
      return 'grid-cols-4';
    case '3x2':
      return 'grid-cols-1';
    case '2x2':
      return 'grid-cols-2';
    case '1x2':
      return 'grid-cols-2';
    default:
      return 'grid-cols-2';
  }
}
export function CalculateImageSizeMultiComponent(currentStyle?: string) {
  switch (currentStyle) {
    case '3x4':
      return 'max-h-[170px] max-w-[170px] aspect-square h-full w-full';
    case '2x4':
      return 'max-h-[122px] max-w-[128px] aspect-[128/122] h-full w-full';
    case '1x4':
      return 'max-w-[280px] max-h-[280px] h-full w-full aspect-square';
    case '3x2':
      return 'max-h-[170px] max-w-[357px]  h-full w-full aspect-[357/170]';
    case '2x2':
      return 'max-h-[130px] max-w-[272px] w-full h-full aspect-[272/130]';
    case '1x2':
      return 'max-h-[296px] max-w-[576px] w-full h-full aspect-[576/296]';
    default:
      return 'max-h-[170px] max-w-[170px] aspect-square h-full w-full';
  }
}

export function NumberOfItemRenderedMultiComponent(currentStyle?: string) {
  switch (currentStyle) {
    case '3x4':
      return 4;
    case '2x4':
      return 4;
    case '1x4':
      return 4;
    case '3x2':
      return 2;
    case '2x2':
      return 2;
    case '1x2':
      return 2;
    default:
      return 4;
  }
}
export function NumberOfItemRenderedSalesBannerContainer(
  currentStyle?: string
) {
  switch (currentStyle) {
    case '3x4':
      return 3;
    case '2x4':
      return 2;
    case '1x4':
      return 1;
    case '3x2':
      return 3;
    case '2x2':
      return 2;
    case '1x2':
      return 1;
    default:
      return 2;
  }
}

export function shouldScaled(currentStyle?: string) {
  switch (currentStyle) {
    case '3x4':
      return true;
    case '2x4':
      return true;
    case '1x4':
      return false;
    case '3x2':
      return false;
    case '2x2':
      return false;
    case '1x2':
      return false;
    default:
      return false;
  }
}
