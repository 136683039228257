'use client';

import Image from 'next/image';
import BaseSwiper from 'components/swiper/base-swiper';
import HomeBannersLayout from 'components/layout/homepage/home-banners-layout';
import { OfferWeekType, ItemOfferType } from 'types/cms/offer-of-week';
import tailwindMerge from 'utils/tailwind-merge';
import clsx from 'clsx';
import './offer.css';
import OfferBadge from './offer-badge';
import { ShowPromoText } from './offer-style-functions';
import ContentfulLink from 'components/contentful-link';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { gtmSetSelectPromotion } from 'lib/gtm';

export default function OfferCarousel({
  data,
  componentIndex,
  language,
  region,
}: {
  data: OfferWeekType;
  componentIndex?: number;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  function handleGtm(categoryItem: ItemOfferType) {
    gtmSetSelectPromotion(categoryItem, 'Clearance Sale');
  }
  return (
    <>
      <div className='offer-component'>
        <HomeBannersLayout
          title={data.header}
          titleColor={data?.color_theme?.header_text_color}
          showAll={data?.label}
          showAllLink={data?.link}
          showAllLinkColor={data.color_theme.cta_button_label_text_color}
          backgroundColor={data?.color_theme?.background_color}
          backgroundImage={data?.color_theme?.background_image}
        >
          <div className='relative'>
            <BaseSwiper
              swiperProps={{
                navigation: {
                  nextEl: `.custom-${componentIndex}-offer-right-button`,
                  prevEl: `.custom-${componentIndex}-offer-left-button`,
                  disabledClass: 'swiper-button-disabled',
                },
                breakpoints: {
                  0: {
                    slidesPerView: data.items.length,
                    enabled: false,
                  },
                  640: {
                    slidesPerView: 4,
                    enabled: true,
                  },
                  768: {
                    slidesPerView: 4.4,
                    enabled: true,
                  },
                  1024: {
                    slidesPerView: 5.4,
                    enabled: true,
                  },
                },
                spaceBetween: 12,
                fadelast: 'show',
              }}
              className='offer-slider'
            >
              {data?.items.map((categoryItem: ItemOfferType, index: number) => (
                <div
                  key={index}
                  className='h-full'
                  onClick={() => handleGtm(categoryItem)}
                >
                  <ContentfulLink
                    item={categoryItem}
                    className='block h-full'
                    language={language}
                    region={region}
                  >
                    <div
                      className={tailwindMerge(
                        clsx(
                          'relative flex h-full flex-col items-center pb-4 lg:pb-0',
                          'rounded-[10px]'
                        )
                      )}
                      style={{
                        backgroundColor:
                          categoryItem.color_theme?.background_color,
                        backgroundImage: `linear-gradient(to bottom, ${
                          categoryItem?.color_theme?.background_gradient
                            ? categoryItem?.color_theme?.background_gradient[0]
                            : ''
                        }, ${
                          categoryItem?.color_theme?.background_gradient
                            ? categoryItem?.color_theme?.background_gradient[1]
                            : ''
                        })`,
                      }}
                    >
                      <div
                        className={tailwindMerge(
                          clsx('relative aspect-square w-full')
                        )}
                      >
                        {categoryItem.mobile_image && (
                          <Image
                            src={categoryItem.mobile_image}
                            alt={categoryItem?.label || ''}
                            fill
                            sizes='100vw'
                            className={tailwindMerge(
                              clsx('rounded-[10px] lg:hidden')
                            )}
                          />
                        )}

                        {categoryItem?.web_image && (
                          <Image
                            src={categoryItem.web_image}
                            alt={categoryItem?.label || ''}
                            fill
                            sizes='100vw'
                            className={tailwindMerge(
                              clsx('hidden rounded-[10px] lg:block')
                            )}
                          />
                        )}
                      </div>
                      {categoryItem.label && (
                        <>
                          <div
                            className={tailwindMerge(
                              clsx(
                                'mt-2 line-clamp-1 block h-6 w-[calc(100%-2rem)] overflow-hidden text-clip whitespace-nowrap text-center !text-custom-sm font-semibold text-gray-dark'
                              )
                            )}
                            style={{
                              color: categoryItem.color_theme?.label_text_color,
                            }}
                          >
                            {categoryItem.label}
                          </div>
                          <div
                            className={clsx(
                              ShowPromoText('default')
                                ? 'flex'
                                : 'hidden md:flex',
                              'w-full justify-center'
                            )}
                          >
                            {categoryItem.promo_badge_text ||
                            categoryItem.promo_badge_sub_text ? (
                              <OfferBadge
                                promo_badge_text={categoryItem.promo_badge_text}
                                promo_badge_style={
                                  categoryItem.promo_badge_style
                                }
                                promo_badge_text_color={
                                  categoryItem.color_theme
                                    ?.promo_badge_text_color
                                }
                                promo_badge_icon={
                                  categoryItem.color_theme?.promo_badge_icon
                                }
                                promo_badge_line_color={
                                  categoryItem.color_theme
                                    ?.promo_badge_line_color
                                }
                                promo_badge_background_color={
                                  categoryItem.color_theme
                                    ?.promo_badge_background_color
                                }
                                promo_badge_sub_text={
                                  categoryItem.promo_badge_sub_text
                                }
                              />
                            ) : (
                              <div className='h-6 w-full md:h-12'></div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </ContentfulLink>
                </div>
              ))}
            </BaseSwiper>
            {data?.items.length && data.items.length > 5 && (
              <>
                <button
                  className={clsx(
                    `custom-${componentIndex}-offer-left-button swiper-button-prev custom-swiper-button-prev`
                  )}
                ></button>
                <button
                  className={clsx(
                    `custom-${componentIndex}-offer-right-button swiper-button-next custom-swiper-button-next`
                  )}
                ></button>
              </>
            )}
          </div>
        </HomeBannersLayout>
      </div>
    </>
  );
}
