'use client';

import BaseSwiper, { CustomSwiperType } from 'components/swiper/base-swiper';
import { CategoriesStyle } from 'types/cms/categories-banner';
import tailwindMerge from 'utils/tailwind-merge';
import HomeBannersLayout from 'components/layout/homepage/home-banners-layout';
import ContentfulLink from 'components/contentful-link';
import clsx from 'clsx';
import { BrandsCarouselType } from 'types/cms/brands-banner';
import { useEffect } from 'react';
import { gtmSetViewPromotion } from 'lib/gtm';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { Media } from 'components/media';

export default function BrandsCarousel({
  data,
  componentIndex,
  language,
  region,
}: {
  data?: BrandsCarouselType;
  componentIndex: number;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  let customClassName = '';
  const currentStyle = data?.style || CategoriesStyle.Circle;
  const calculateStyle = () => {
    return {
      // !!! DON'T REMOVE THIS COMMENT. Since these are added dynamically, Tailwind will remove them thinking they are not used
      // Having them anywhere in the code, including inside of a comment, will prevent tailwind from removing them.
      //
      // aspect-[90/112] aspect-[196/320] aspect-[172/88] aspect-[100/70] aspect-[112/83]
      imageAspectRatio: (() => {
        switch (currentStyle) {
          case CategoriesStyle.Rectangle:
          case CategoriesStyle.RectangleWithTextBackground:
          case CategoriesStyle.RectangleMultiLine:
          case CategoriesStyle.SpecialMultiline:
            return '90/112';
          case CategoriesStyle.Tall:
          case CategoriesStyle.SpecialMultilineTall:
            return '196/320';
          case CategoriesStyle.Wide:
            return '172/88';
          case CategoriesStyle.Style12:
            return '100/70';
          case CategoriesStyle.Style13:
            return '112/83';
          default:
            return null;
        }
      })(),
      imageIsCircular: [
        CategoriesStyle.Circle,
        CategoriesStyle.CircleMultiLine,
      ].includes(currentStyle),
      titleHasBackground: [
        CategoriesStyle.RectangleWithTextBackground,
        CategoriesStyle.Wide,
        CategoriesStyle.Style13,
      ].includes(currentStyle),
      titleNotCentered: [CategoriesStyle.Style12].includes(currentStyle),
      titleIsStyled: [CategoriesStyle.Style13].includes(currentStyle),
      includeDescription: [CategoriesStyle.Style13].includes(currentStyle),
      hasShadow: [
        CategoriesStyle.RectangleWithTextBackground,
        CategoriesStyle.Wide,
        CategoriesStyle.Style13,
      ].includes(currentStyle),
    };
  };

  const calculateSwiperProps = () => {
    const temp: CustomSwiperType = {
      navigation: {
        nextEl: `.custom-${componentIndex}-swiper-button-next`,
        prevEl: `.custom-${componentIndex}-swiper-button-prev`,
        disabledClass: 'swiper-button-disabled',
      },
      breakpoints: data?.breakpoints || {
        350: {
          slidesPerView: 4.7,
        },
        768: {
          slidesPerView: 6.7,
        },
        1024: {
          slidesPerView: 10,
        },
      },
      spaceBetween: 12,
      wrapperClass: 'has-overflowing-shadow',
    };

    if (
      [
        CategoriesStyle.CircleMultiLine,
        CategoriesStyle.SquareMultiLine,
        CategoriesStyle.RectangleMultiLine,
      ].includes(currentStyle)
    ) {
      temp.grid = {
        rows: 2,
        fill: 'row',
      };
    } else if (
      [
        CategoriesStyle.SpecialMultiline,
        CategoriesStyle.SpecialMultilineTall,
      ].includes(currentStyle)
    ) {
      customClassName = 'special-multiline';
      temp.grid = {
        rows: 2,
        fill: 'column',
      };
    } else {
      delete temp.grid;
    }

    if (
      [CategoriesStyle.Circle, CategoriesStyle.CircleMultiLine].includes(
        currentStyle
      )
    ) {
      temp.fadelast = 'show';
    }
    return temp;
  };

  const style = calculateStyle();
  const swiperProps = calculateSwiperProps();

  const brandImage = (
    srcSet: {
      mobileImage: string;
      desktopImage: string;
      desktopImageWidth?: number;
      desktopImageHeight?: number;
      mobileImageWidth?: number;
      mobileImageHeight?: number;
    },
    title?: string
  ) => {
    return (
      <Media
        srcset={srcSet}
        alt={title || 'NAHDI'}
        className={tailwindMerge(
          clsx(
            'rounded-[10px]',
            style?.imageIsCircular && 'rounded-full',
            style?.titleHasBackground && title && 'rounded-b-none'
          )
        )}
        fill
        decoding='async'
        fetchPriority='high'
      />
    );
  };

  useEffect(() => {
    gtmSetViewPromotion(data, 'Brand Carousel');
  }, [data]);

  return (
    <HomeBannersLayout
      title={data?.title || ''}
      titleColor={data?.titleColor}
      showAll={data?.ctaText}
      showAllLink={data?.ctaLink}
      showAllLinkColor={data?.ctaColor}
      backgroundColor={data?.background?.color}
      backgroundImage={data?.background?.image}
    >
      <div className='relative'>
        <BaseSwiper swiperProps={swiperProps} className={customClassName}>
          {data?.items?.map((categoryItem) => (
            <div key={categoryItem.id} className='flex h-full flex-col'>
              <ContentfulLink
                item={categoryItem}
                className='h-full'
                language={language}
                region={region}
              >
                <div
                  className={tailwindMerge(
                    clsx(
                      'relative flex flex-col items-center',
                      'h-full rounded-[10px]',
                      style?.hasShadow && 'shadow-lg'
                    )
                  )}
                >
                  <div
                    className={tailwindMerge(
                      clsx(
                        'relative aspect-square w-full',
                        style?.imageAspectRatio &&
                          `aspect-[${style.imageAspectRatio}]`
                      )
                    )}
                  >
                    <div>
                      {brandImage(
                        {
                          desktopImage: categoryItem.web_image,
                          mobileImage: categoryItem.mobile_image,
                          desktopImageWidth: categoryItem.web_image_width,
                          desktopImageHeight: categoryItem.web_image_height,
                          mobileImageWidth: categoryItem.mobile_image_width,
                          mobileImageHeight: categoryItem.mobile_image_height,
                        },
                        categoryItem.title
                      )}
                      {categoryItem?.mobile_image || categoryItem?.web_image ? (
                        <div className='swiper-lazy-preloader'></div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
              </ContentfulLink>
            </div>
          ))}
        </BaseSwiper>
        {data?.items?.length && data.items.length > 8 && (
          <>
            <button
              className={`custom-${componentIndex}-swiper-button-prev custom-swiper-button-prev swiper-button-prev`}
            ></button>
            <button
              className={`custom-${componentIndex}-swiper-button-next custom-swiper-button-next swiper-button-next`}
            ></button>
          </>
        )}
      </div>
    </HomeBannersLayout>
  );
}
