'use client';

import { useState } from 'react';
import PromotionPopUp from '../promotion-popup';
import clsx from 'clsx';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function HomepagePopupOrder({
  popupDatas,
  language,
  region,
  ipAddress,
  dynamicYieldEnable,
}: {
  popupDatas: any[];
  language: LanguageCodesType;
  region: RegionCodesType;
  ipAddress: string;
  dynamicYieldEnable?: boolean;
}) {
  const [activePopupIndex, setActivePopupIndex] = useState(0);

  if (!popupDatas) return null;
  return (
    <div
      key={`homepage-popup-${activePopupIndex}`}
      className={clsx([popupDatas[activePopupIndex]?.wrapperClassname])}
    >
      <PromotionPopUp
        data={popupDatas[activePopupIndex]}
        onCloseModal={() => {
          if (activePopupIndex !== popupDatas.length - 1)
            setActivePopupIndex(activePopupIndex + 1);
        }}
        language={language}
        region={region}
        ipAddress={ipAddress}
        dynamicYieldEnable={dynamicYieldEnable}
      />
    </div>
  );
}
