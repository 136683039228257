export type ExperAdviceCardItem = {
  image?: string;
  date?: string;
  title: string;
  description?: string;
  linkHref?: string;
  linkText?: string;
  blog_card_style?: string;
};

export type ColorThemeType = {
  badge_text_background_color: string;
  badge_text_color: string;
  cta_link_label_color: string;
  description_color: string;
  title_color: string;
  background_color: string;
  header_color: string;
  link_color: string;
  label: string;
};

export const enum ExpertAdviceStyle {
  /* eslint-disable no-unused-vars */
  FULL_WIDTH_IMAGE = 'FULL WIDTH IMAGE',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export const enum ExpertAdviceCardStyle {
  /* eslint-disable no-unused-vars */
  ONE_CONTENT_CARD = '1 Content Card',
  TWO_CONTENT_CARD = '2 Content Card',
  THREE_CONTENT_CARD = '3 Content Card',
  THREE_PLUS_CONTENT_CARD = '3+ Content Card',
}

export type ExpertAdvice = {
  title: string;
  backgroundColor: string;
  mobilePerView: number;
  tabletPerView: number;
  desktopPerView: number;
  style: ExpertAdviceStyle;
  cards: Array<ExperAdviceCardItem>;
};

export type BlogType = {
  items: Array<ExperAdviceCardItem>;
  header: string;
  color_theme: ColorThemeType;
  label: string;
  link: string;
  link_type: string;
  calculated_relative_url?: string;
  style: string;
};

export type BlogItemType = {
  id?: number;
  link_text?: string;
  link_href?: string;
  web_image?: string;
  web_image_width?: number;
  web_image_height?: number;
  calculated_relative_url?: string;
  mobile_image?: string;
  mobile_image_width?: number;
  mobile_image_height?: number;
  video_url?: string;
  badge_text?: string;
  color_theme?: ColorThemeType;
  cta_link_label?: string;
  cta_link?: string;
  description?: string;
  linkHref?: string;
  linkText?: string;
  date?: string;
  title: string;
  image?: string;
  asset_type?: string;
  cta_link_type?: string;
  link_type?: string;
  blog_card_style?: string;
};
