import { useCountdown } from 'hooks/use-countdown';
import { useEffect, useState } from 'react';

export function StripeCountdownRenderer({
  content,
  date,
  onCountdownEnds,
}: {
  content: string;
  date: Date;
  onCountdownEnds: () => void;
}) {
  const { countDownObj: timeLeft, isExpired } = useCountdown(
    date,
    true,
    onCountdownEnds
  );
  const [isLoading, setIsLoading] = useState(true);

  const countdownTimerLabel = content;
  const [timeLabel, setTimeLabel] = useState('');
  useEffect(() => {
    if (timeLeft.sec || timeLeft.min || timeLeft.hrs || timeLeft.days) {
      setTimeLabel(
        countdownTimerLabel
          .replace(
            '{{DD}}',
            timeLeft.days && +timeLeft.days > 0 ? timeLeft.days.toString() : '0'
          )
          .replace(
            '{{HH}}',
            timeLeft.hrs && +timeLeft.hrs > 0 ? timeLeft.hrs.toString() : '0'
          )
          .replace(
            '{{MM}}',
            timeLeft.min && +timeLeft.min > 0 ? timeLeft.min.toString() : '0'
          )
          .replace(
            '{{SS}}',
            timeLeft.sec && +timeLeft.sec > 0 ? timeLeft.sec.toString() : '0'
          )
      );
      setIsLoading(false);
    }
  }, [timeLeft, countdownTimerLabel]);

  return (
    !isExpired &&
    (isLoading ? (
      <span className='h-auto animate-pulse rounded-xl bg-neutral-100 text-transparent'>
        time label animation
      </span>
    ) : (
      <div
        dangerouslySetInnerHTML={{
          __html: timeLabel ?? '',
        }}
      />
    ))
  );
}

export default StripeCountdownRenderer;
