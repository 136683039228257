'use client';

import { useEffect, useState } from 'react';
import ProductPromotionModalContent from './products-content';
import MobileSaleModal from '../product-modal/mobile-sale-modal';
import SecondaryOptionModal from 'components/secondary-option-modal';
import Modal from 'components/modal';

import { mobileSize, useMediaQuery } from 'hooks/use-media-query';
import { PromotionPopupType } from 'types/cms/promotion-popup';
import { getProductsByProductIds } from 'lib/api';
import { ProductItemType } from 'types/cms/product-carousel';
import {
  getProductExperienceData,
  useGetExperienceBody,
} from 'hooks/use-experience';
import { useCookies } from 'react-cookie';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { COOKIE_DY_ID_SERVER, COOKIE_DY_SESSION } from 'utils/constant-cookies';

export default function ProductPromotionModal({
  data,
  onCloseModal,
  popupType,
  language,
  region,
  ipAddress,
  dynamicYieldEnable,
}: {
  data: PromotionPopupType;
  onCloseModal?: () => void;
  popupType: string;
  language: LanguageCodesType;
  region: RegionCodesType;
  ipAddress: string;
  dynamicYieldEnable?: boolean;
}) {
  const [openModal, setOpenModal] = useState(true);
  const [cookies, setCookie] = useCookies([
    COOKIE_DY_SESSION,
    COOKIE_DY_ID_SERVER,
  ]);
  const [products, setProducts] = useState<ProductItemType[]>([]);
  const requestBody = useGetExperienceBody(
    cookies._dyid_server?.toString() || '',
    cookies._dyjsession,
    data.campaign_id,
    language,
    region,
    ipAddress,
    dynamicYieldEnable
  );
  const isMobile = useMediaQuery(mobileSize);

  useEffect(() => {
    const fetchProductManuel = async () => {
      const products = await getProductsByProductIds(
        data.product_id_list,
        language,
        region
      );
      setProducts(products);
    };
    const fetchProductDy = () => {
      getProductExperienceData(
        requestBody,
        setProducts,
        data.product_id_list,
        '',
        '',
        language,
        region
      );
    };

    if (data.data_source === 'DY') {
      fetchProductDy();
    } else {
      if (data.product_id_list) {
        fetchProductManuel();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data_source, data.product_id_list, setCookie]);

  useEffect(() => {
    if (!openModal && onCloseModal) onCloseModal();
  }, [openModal, onCloseModal]);

  return (
    <>
      {popupType === 'PRODUCT BS' && (
        <SecondaryOptionModal
          modalId='product-promotion-modal'
          open={openModal}
          setOpen={setOpenModal}
          title=''
          modalWidth='sm:w-[960px]'
          modalClassname='!items-center !overflow-hidden'
          contentClassName='bg-light-gray-200'
          language={language}
          region={region}
        >
          <ProductPromotionModalContent
            popupType={popupType}
            products={products}
            promotionPopupData={data}
          />
        </SecondaryOptionModal>
      )}
      {popupType === 'PRODUCT' &&
        (!isMobile ? (
          <Modal
            modalId='product-promotion-modal'
            open={openModal}
            setOpen={setOpenModal}
            title=''
            modalWidth='sm:w-[480px] !h-auto lg:w-[960px]'
            contentClassName='bg-light-gray-200 !pb-0'
            backgroundColor={data.color_theme?.background_color}
            backgroundImage={data.background_image}
            closeButtonImage={data.close_button}
            language={language}
            region={region}
          >
            <ProductPromotionModalContent
              popupType={popupType}
              products={products}
              promotionPopupData={data}
            />
          </Modal>
        ) : (
          openModal && (
            <MobileSaleModal
              setOpenModal={setOpenModal}
              popupType={popupType}
              products={products}
              promotionPopupData={data}
            />
          )
        ))}
    </>
  );
}
