'use client';
import React from 'react';
import { WishlistResponseType } from 'types/account/wishlist';
import ProductCarousel from './product-carousel';
import { HomePageProductCarouselType } from 'types/cms/product-carousel';
import { LanguageCodesType, RegionCodesType } from 'utils';

function WishlistProductCarousel({
  wishList,
  componentIndex,
  data,
  language,
  region,
}: {
  componentIndex: number;
  wishList: WishlistResponseType;
  data?: HomePageProductCarouselType;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const products = wishList?.items?.map((item) => item.product?.data) || [];

  return (
    <ProductCarousel
      algoliaType={false}
      componentIndex={componentIndex}
      products={products}
      availableOnly={data?.available_only}
      fadeLast={data?.color_theme?.slider_fade_effect}
      swiper
      language={language}
      region={region}
    />
  );
}

export default WishlistProductCarousel;
