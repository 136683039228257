/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
'use client';

import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import clsx from 'clsx';
import { Underline } from 'components/buttons/underline';
import {
  BlogItemType,
  ExpertAdviceStyle,
  ExpertAdviceCardStyle,
} from 'types/cms/expert-advice';
import { PlayIcon } from 'components/icon';
import SecondaryOptionModal from 'components/secondary-option-modal';
import { LanguageCodesType, RegionCodesType } from 'utils';
import ContentfulLink from 'components/contentful-link';
import { Media } from 'components/media';

function ExperAdviceCard({
  advice,
  style,
  cardStyle,
  videoPlay = false,
  language,
  region,
}: {
  advice: BlogItemType;
  style: ExpertAdviceStyle | string;
  cardStyle: ExpertAdviceCardStyle | string;
  videoPlay?: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const cardStyleMoreThanTwo =
    cardStyle === ExpertAdviceCardStyle.THREE_CONTENT_CARD ||
    cardStyle === ExpertAdviceCardStyle.THREE_PLUS_CONTENT_CARD;
  const cardStyleLessThanThree =
    cardStyle === ExpertAdviceCardStyle.ONE_CONTENT_CARD ||
    cardStyle === ExpertAdviceCardStyle.TWO_CONTENT_CARD;
  const cardStyleMoreThanOne =
    cardStyle === ExpertAdviceCardStyle.TWO_CONTENT_CARD ||
    cardStyle === ExpertAdviceCardStyle.THREE_CONTENT_CARD ||
    cardStyle === ExpertAdviceCardStyle.THREE_PLUS_CONTENT_CARD;

  const fullWidthCondition =
    style === ExpertAdviceStyle.FULL_WIDTH_IMAGE && cardStyleMoreThanTwo;

  const styleClassName =
    style === ExpertAdviceStyle.LEFT ||
    cardStyleLessThanThree ||
    style === ExpertAdviceStyle.RIGHT ||
    cardStyleLessThanThree
      ? 'flex flex-col lg:flex-row lg:items-center' +
        (style === ExpertAdviceStyle.RIGHT ? ' lg:!flex-row-reverse' : '')
      : '';

  const imageRatio = fullWidthCondition
    ? 'w-full h-[127px] lg:h-[291px]'
    : 'w-full h-[127px] lg:w-[160px] lg:h-[90px] xl:w-[270px] xl:h-[200px]';

  const [videoPlayModalOpen, setVideoPlayModal] = useState(false);
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  function handleVideoPlay() {
    if (videoPlay) setVideoPlayModal(true);
  }

  const contentTopHtml = () => {
    return (
      <>
        <Media
          srcset={{
            mobileImage: advice?.mobile_image,
            desktopImage: advice?.web_image,
            desktopImageWidth: advice?.web_image_width,
            desktopImageHeight: advice?.web_image_width,
            mobileImageWidth: advice?.mobile_image_width,
            mobileImageHeight: advice?.mobile_image_height,
          }}
          className={`swiper-lazy relative h-full w-full object-cover ${
            fullWidthCondition
              ? 'rounded-tl-lg rounded-tr-lg'
              : 'rounded-tl-lg rounded-tr-lg lg:rounded-lg'
          }`}
          fill
        />
        <div className='swiper-lazy-preloader'></div>
        {advice.video_url && isClient && (
          <>
            <div
              onClick={() => {
                handleVideoPlay();
              }}
              className='absolute left-0 right-0 top-[50%] z-[9] mx-auto h-14 w-14 -translate-y-1/2 cursor-pointer lg:h-28 lg:w-28'
            >
              <PlayIcon className='h-full w-full' />
            </div>

            <ReactPlayer
              width='192'
              height='456'
              url={advice.video_url}
              className={`blog-video-source ${imageRatio} ${
                style === ExpertAdviceStyle.FULL_WIDTH_IMAGE ||
                cardStyleMoreThanTwo
                  ? 'video-top-radius'
                  : 'video-radius'
              }`}
              controls={false}
              pip={true}
              muted
              playing={false}
            />
            <source src={advice.video_url} type='video/mp4' />
          </>
        )}

        {advice.date && (
          <div className='absolute bottom-4 left-4 rounded-xl bg-black bg-opacity-50 px-2 py-1'>
            <p className='text-custom-xs text-light-gray-300'>{advice.date}</p>
          </div>
        )}
        {advice.badge_text && fullWidthCondition && (
          <div
            className='absolute bottom-4 left-4 rounded-xl bg-black bg-opacity-50 px-2 py-1'
            style={{
              backgroundColor: advice.color_theme?.badge_text_background_color,
            }}
          >
            <p
              className='text-custom-xs text-light-gray-300'
              style={{ color: advice.color_theme?.badge_text_color }}
            >
              {advice.badge_text}
            </p>
          </div>
        )}
        {advice.badge_text && !fullWidthCondition && (
          <div
            className='absolute bottom-4 left-4 rounded-xl bg-black bg-opacity-50 px-2 py-1 lg:hidden'
            style={{
              backgroundColor: advice.color_theme?.badge_text_background_color,
            }}
          >
            <p
              className='text-custom-xs text-light-gray-300'
              style={{ color: advice.color_theme?.badge_text_color }}
            >
              {advice.badge_text}
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={clsx('h-full rounded-lg bg-white', styleClassName)}
        style={{ backgroundColor: advice.color_theme?.background_color }}
      >
        <div className={`${fullWidthCondition ? 'p-0' : 'lg:p-4'} `}>
          {videoPlay ? (
            <div className={`relative ${imageRatio}`}>{contentTopHtml()}</div>
          ) : (
            <ContentfulLink
              item={{ link: advice.link_href, link_type: advice.link_type }}
              className={`relative block ${imageRatio}`}
              language={language}
              region={region}
            >
              {contentTopHtml()}
            </ContentfulLink>
          )}
        </div>

        <div className='flex flex-col items-start p-3 sm:p-4'>
          {advice.badge_text && !fullWidthCondition && (
            <div
              className='mb-3 hidden rounded-xl bg-black bg-opacity-50 px-2 py-1 lg:flex'
              style={{
                backgroundColor:
                  advice.color_theme?.badge_text_background_color,
              }}
            >
              <p
                className='text-custom-xs text-light-gray-300'
                style={{ color: advice.color_theme?.badge_text_color }}
              >
                {advice.badge_text}
              </p>
            </div>
          )}
          <p
            className={`mb-2 sm:mb-0 ${
              cardStyleMoreThanOne
                ? 'line-clamp-2 h-[36px] sm:line-clamp-1 sm:h-[18px]'
                : 'line-clamp-1'
            } ${
              style === ExpertAdviceStyle.FULL_WIDTH_IMAGE ||
              cardStyleMoreThanTwo
                ? 'h-[18px] text-custom-base'
                : 'h-[18px] text-custom-base lg:h-[21px] lg:text-custom-lg'
            }`}
            style={{ color: advice.color_theme?.title_color }}
          >
            {advice.title}
          </p>

          {advice.description && (
            <div className={`${cardStyleMoreThanOne ? 'hidden sm:block' : ''}`}>
              <p
                className={`mt-0 !leading-[20px] text-gray sm:mt-3 ${
                  fullWidthCondition ? 'mb-2 sm:mb-[25px]' : 'mb-2'
                } ${
                  style === ExpertAdviceStyle.FULL_WIDTH_IMAGE ||
                  cardStyleMoreThanTwo
                    ? 'text-custom-sm'
                    : 'text-custom-sm lg:text-custom-base'
                } ${
                  advice.title && advice.link_text
                    ? 'line-clamp-3 h-[60px]'
                    : 'line-clamp-6 h-[120px] lg:line-clamp-3 lg:h-[60px]'
                }`}
                style={{ color: advice.color_theme?.description_color }}
              >
                {advice.description}
              </p>
            </div>
          )}

          {advice.link_text && (
            <Underline
              href={advice?.link_href || ''}
              color={advice.color_theme?.cta_link_label_color}
              className='h-[18px]'
            >
              {advice.link_text}
            </Underline>
          )}
        </div>
      </div>
      <SecondaryOptionModal
        modalId='blog-video-modal'
        open={videoPlayModalOpen}
        setOpen={setVideoPlayModal}
        title=''
        modalWidth='w-[auto] md:w-[600px]'
        language={language}
        region={region}
      >
        <div className='mb-4 mt-8 h-[200px] lg:h-[300px]'>
          <ReactPlayer
            width='192'
            height='456'
            url={advice.video_url}
            className={'blog-video-source video-radius h-full'}
            controls={true}
            pip={true}
            muted={true}
            playing={videoPlayModalOpen}
          />
          <source src={advice.video_url} type='video/mp4' />
        </div>
      </SecondaryOptionModal>
    </>
  );
}

export default ExperAdviceCard;
