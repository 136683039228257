import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

const Price = ({
  amount,
  className,
  currencyCode = 'SAR',
  currencyCodeClassName,
  lowPrice,
  lowPriceClassName,
  customStyle,
}: {
  amount: number | string;
  className?: string;
  currencyCode: string;
  currencyCodeClassName?: string;
  lowPrice?: number | string;
  lowPriceClassName?: string;
  customStyle?: React.CSSProperties;
} & React.ComponentProps<'div'>) => (
  <div
    suppressHydrationWarning={true}
    className={clsx(className, 'flex items-center')}
    style={customStyle || {}}
  >
    {lowPrice && (
      <div>
        {lowPrice &&
          `${new Intl.NumberFormat(undefined, {}).format(
            parseFloat(lowPrice as string)
          )}`}
        &nbsp;
        <span className={clsx('inline', currencyCodeClassName)}>
          {currencyCode}
        </span>
      </div>
    )}

    <div
      className={twMerge(
        'flex',
        lowPrice && 'mx-2 flex text-lg text-gray-500 line-through',
        lowPriceClassName
      )}
    >
      {`${new Intl.NumberFormat(undefined, {
        // style: 'currency',
        // currency: currencyCode,
        // currencyDisplay: 'narrowSymbol'
      }).format(parseFloat(amount as string))}`}
      {/*<span className={clsx('ml-1 inline', currencyCodeClassName)}>{`${currencyCode}`}</span>*/}
      <span className={clsx('inline', currencyCodeClassName)}>
        &nbsp;
        {currencyCode}
      </span>
    </div>
  </div>
);

export default Price;
