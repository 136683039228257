import clsx from 'clsx';
import { ItemOfferType } from 'types/cms/offer-of-week';

export function CalculateGrid(currentStyle: string, isClearanceSale?: boolean) {
  switch (currentStyle) {
    case '1 FULL':
      return 'grid-cols-1 lg:grid-cols-4';
    case '1 TOP 2 BELOW':
      return 'grid-cols-2 lg:grid-cols-4';
    case '1 TOP 3 BELOW':
      return 'grid-cols-3 lg:grid-cols-5';
    case '1X2 GRID':
      return 'grid-cols-1 lg:grid-cols-4';
    case '2X2 GRID':
      return 'grid-cols-2 lg:grid-cols-4';
    case '2X2 RECTANGLE':
      return 'grid-cols-2 lg:grid-cols-4';
    default:
      if (isClearanceSale) {
        return 'grid-cols-2 lg:grid-cols-6';
      } else {
        return 'grid-cols-2 lg:grid-cols-6';
      }
  }
}

export function CalculateColSpan(currentStyle: string, index: number) {
  switch (currentStyle) {
    case '1 FULL':
      return 'col-span-full lg:col-span-1';
    case '1 TOP 2 BELOW':
      return index === 0 ? 'col-span-full' : 'col-span-1';
    case '1 TOP 3 BELOW':
      return index === 0 ? 'col-span-full lg:col-span-2' : 'col-span-1';
    case '1x2 GRID':
      return 'col-span-full';
    case '2x2 GRID':
      return 'col-span-1';
    case '2X2 RECTANGLE':
    case '2x2 RECTANGLE':
      return 'col-span-1';
    default:
      return 'col-span-1';
  }
}
export function CalculateImageSize(
  currentStyle: string,
  index: number,
  isClearanceSale?: boolean
) {
  switch (currentStyle) {
    case '1 FULL':
      return 'w-full h-full aspect-square';
    case '1 TOP 2 BELOW':
      if (index === 0) {
        return 'w-full h-[158px] lg:col-span-2 lg:h-[276px]';
      } else {
        return 'col-span-1 w-full h-full aspect-square lg:h-[276px]';
      }
    case '1 TOP 3 BELOW':
      if (index === 0) {
        return 'w-full h-[158px] lg:col-span-2 lg:h-[220px]';
      } else {
        return 'w-full h-auto aspect-square lg:h-[220px]';
      }
    case '1X2 GRID':
      return 'w-full h-[158px] lg:col-span-2 lg:h-[276px] h-[158px]';
    case '2x2 GRID':
    case '2X2 GRID':
      return 'w-full h-full aspect-square lg:col-span-1 lg:h-[280px]';
    case '2X2 RECTANGLE':
    case '2x2 RECTANGLE':
      return 'w-full h-[260px] lg:col-span-1';
    default:
      if (isClearanceSale) {
        return 'w-full h-[260px] lg:h-[279px] lg:col-span-1';
      } else {
        return 'w-full h-[228px] lg:h-[279px] lg:col-span-1';
      }
  }
}

export function NumberOfItemRendered(
  currentStyle: string,
  isMobile?: boolean,
  isClearanceSale?: boolean
) {
  switch (currentStyle) {
    case '1 FULL':
      return isMobile ? 1 : 4;
    case '1 TOP 2 BELOW':
      return 3;
    case '1 TOP 3 BELOW':
      return 4;
    case '1X2 GRID':
      return 2;
    case '2x2 GRID':
      return 4;
    case '2X2 GRID':
      return 4;
    case '2x2 RECTANGLE':
      return 4;
    case '2X2 RECTANGLE':
      return 4;
    default:
      if (isClearanceSale) {
        return isMobile ? 8 : 12;
      } else {
        return isMobile ? 8 : 24;
      }
  }
}
export function CalculateTimerPosition(currentStyle: string) {
  switch (currentStyle) {
    case '2X2 GRID': {
      return ' top-3 left-3 right-3 z-10';
    }
    case '2x2 GRID': {
      return ' top-3 left-3 right-3 z-10';
    }
    case '2X2 RECTANGLE': {
      return ' top-3 left-3 right-3 z-10';
    }
    case '2x2 RECTANGLE': {
      return ' top-3 left-3 right-3 z-10';
    }
    default: {
      return ' top-3 right-3 z-10';
    }
  }
}
const getTextClassesAndStyles = (
  categoryItem: ItemOfferType,
  isInside: boolean,
  currentStyle: string,
  index: number,
  isClearanceSale?: boolean
) => {
  const baseStyle = 'min-h-4 w-full overflow-clip whitespace-nowrap';
  const labelColor = { color: categoryItem?.color_theme?.label_text_color };

  switch (currentStyle) {
    case '2X2 GRID':
    case '2x2 GRID': {
      return {
        classes: clsx(
          baseStyle,
          isInside ? 'hidden' : 'lg:block',
          'mt-3 lg:mt-4 text-custom-sm lg:text-custom-base'
        ),
        styles: labelColor,
      };
    }
    case '2x2 RECTANGLE':
    case '2X2 RECTANGLE': {
      return {
        classes: clsx(
          isInside
            ? 'my-3 w-full text-center text-xl lg:hidden'
            : 'mt-3 hidden lg:block text-custom-sm lg:mt-4 lg:min-h-[18px]'
        ),
        styles: labelColor,
      };
    }
    case '1X2 GRID': {
      return {
        classes: clsx(
          isInside
            ? 'absolute bottom-4 mb-3 w-[calc(100%-2rem)] text-custom-lg lg:hidden ltr:left-4 rtl:right-4'
            : 'mt-2 hidden lg:block text-xs lg:mt-3 lg:text-custom-base'
        ),
        styles: labelColor,
      };
    }
    case '1 TOP 3 BELOW':
    case '1 TOP 2 BELOW': {
      return {
        classes: clsx(
          index === 0
            ? isInside
              ? 'absolute bottom-4 w-[calc(100%-2rem)] text-xl lg:hidden ltr:left-4 rtl:right-4'
              : 'hidden lg:block'
            : isInside
            ? 'hidden'
            : 'lg:block',
          'mt-2 text-xs lg:text-custom-base'
        ),
        styles: labelColor,
      };
    }
    case '1 FULL': {
      if (isInside) return null;
      return {
        classes: 'mt-3 lg:mt-4 text-custom-sm lg:text-custom-base',
        styles: labelColor,
      };
    }
    default: {
      {
        if (!isInside) return null;
        if (isClearanceSale) {
          return {
            classes: 'my-3 w-full text-center text-xl font-normal',
            styles: labelColor,
          };
        } else {
          return {
            classes: 'my-2 w-full text-center font-semibold text-custom-sm',
            styles: labelColor,
          };
        }
      }
    }
  }
};
export function CalculateTextProperties(
  categoryItem: ItemOfferType,
  index: number,
  isInside: boolean,
  currentStyle: string,
  isClearanceSale?: boolean
) {
  const textProps = getTextClassesAndStyles(
    categoryItem,
    isInside,
    currentStyle,
    index,
    isClearanceSale
  );
  if (!textProps) return null;

  return (
    <div
      className={clsx(textProps.classes, 'overflow-hidden whitespace-nowrap')}
      style={textProps.styles}
    >
      {categoryItem?.label ?? ' '}
    </div>
  );
}

export function ShowPromoText(currentStyle: string) {
  return (
    currentStyle === '2x2 RECTANGLE' ||
    currentStyle === '2X2 RECTANGLE' ||
    currentStyle === 'default'
  );
}
