'use client';

import { ClockAlarmIcon } from 'components/icons';
import { useCountdown } from 'hooks/use-countdown';
import { useCallback, useEffect, useState } from 'react';
import {
  CountDownStyleType,
  CountdownPropertiesType,
} from 'types/cms/offer-of-week';
import Image from 'next/image';
import clsx from 'clsx';
function ExpiresCountdown({
  date,
  containerClassname,
  showTimer = true,
  itemId,
  style,
  countdownProperties,
  icon,
  childClasses,
}: {
  date: Date;
  containerClassname?: string;
  showTimer?: boolean;
  itemId: string;
  style?: CountDownStyleType;
  countdownProperties?: CountdownPropertiesType;
  icon?: string;
  childClasses?: string;
}) {
  const removeItemOnExpire = useCallback(() => {
    document.getElementById(itemId)?.remove();
  }, [itemId]);
  const { countDownObj: timeLeft, isExpired } = useCountdown(
    date,
    false,
    removeItemOnExpire
  );
  let timeLabel;

  const countdownTimerLabel = countdownProperties?.countdown_timer_label;
  if (countdownTimerLabel) {
    timeLabel = countdownTimerLabel
      .replace(
        '{{DD}}',
        timeLeft.days && +timeLeft.days > 0 ? timeLeft.days.toString() : ''
      )
      .replace(
        '{{HH}}',
        timeLeft.hrs && +timeLeft.hrs > 0 ? timeLeft.hrs.toString() : ''
      )
      .replace(
        '{{MM}}',
        timeLeft.min && +timeLeft.min > 0 ? timeLeft.min.toString() : ''
      );
  }
  let formattedTime = '';
  if (timeLabel) {
    let fixedTimeLabel = timeLabel.split(' ');
    fixedTimeLabel = fixedTimeLabel.filter((item) => /\d/.test(item));
    //fixedTimeLabel.shift();
    formattedTime = fixedTimeLabel.join(' : ');
  }
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (formattedTime) setIsLoading(false);
  }, [formattedTime]);
  return (
    <div
      className={`${containerClassname} ${
        isExpired && 'hidden'
      } flex items-center gap-1 rounded-[50px] border border-dashed border-red bg-primary-red/20 px-2 py-1 text-red`}
      style={style}
    >
      {icon ? (
        <Image
          src={icon}
          alt='clock-alarm'
          width={16}
          height={16}
          className='h-5 w-5'
        />
      ) : (
        <ClockAlarmIcon className='h-5 w-5' />
      )}
      {showTimer &&
        (isLoading ? (
          <span
            className='h-auto w-[85px] animate-pulse rounded-xl bg-neutral-100 text-transparent'
            style={style}
          ></span>
        ) : (
          <div
            className={clsx(
              'flex items-center overflow-hidden whitespace-nowrap text-custom-base font-semibold',
              childClasses
            )}
          >
            {formattedTime}
          </div>
        ))}
    </div>
  );
}

export default ExpiresCountdown;
