import Image from 'next/image';
import { Link } from 'components/link';
import { ButtonLink } from 'components/buttons/button-link';
import Price from 'components/price';
import clsx from 'clsx';
import { ProductItemType } from 'types/cms/product-carousel';
import ResponsiveImage from 'components/responsive-image';
import { PromotionPopupType } from 'types/cms/promotion-popup';

export default function ProductPromotionModalContent({
  popupType,
  products,
  promotionPopupData,
}: {
  popupType: string;
  products: ProductItemType[];
  promotionPopupData: PromotionPopupType;
}) {
  const productHml = (product: ProductItemType, index: number) => {
    return (
      <Link
        key={`promotion-product-${index}`}
        href='/'
        className='relative rounded-xl bg-white p-2'
      >
        <div className='absolute z-10'>
          {product.attributes.badge_image_url && (
            <Image
              src={product.attributes.badge_image_url}
              alt={product.attributes.name}
              width={84}
              height={84}
              sizes='100vw'
              className='h-8 w-[84px] rounded-lg object-cover'
            />
          )}
        </div>
        <ResponsiveImage
          productImage={product.attributes.media_gallery[0]?.types}
          imageAlt={product.attributes.name}
          imageClassName='h-[160px] w-[160px] lg:h-[200px] lg:w-[200px]'
        />
        <div className='my-2 flex flex-col items-start'>
          {/* <div className='flex items-center space-x-1 rounded-3xl bg-red py-1 pl-1 pr-2 rtl:space-x-reverse'>
            <DiscountFilledIcon className='h-[13px] w-[13px] text-white' />
            <span className='text-[11px] font-bold text-white'>
              {product.attributes.promo_badge_text}
            </span>
          </div> */}
        </div>
        <Price
          className='flex-col-reverse !items-start text-xl font-semibold text-red'
          amount={product.attributes.price_after_discount.toString()}
          currencyCode={product.attributes.currency}
          lowPrice={product.attributes.price.toString()}
          lowPriceClassName='text-sm m-0'
          currencyCodeClassName='!mx-0'
        />
      </Link>
    );
  };

  return (
    <div className='flex h-full flex-col gap-4 pb-6 md:h-auto md:!max-h-[80dvh] md:gap-6 lg:mt-0'>
      <div
        className={clsx(
          '-mt-[20px] flex flex-col-reverse items-start space-y-1 lg:-mt-2 rtl:space-x-reverse',
          'lg:flex-row lg:items-center lg:space-x-3 lg:space-y-0'
        )}
      >
        <h2
          className='text-[28px] font-bold text-red lg:text-[32px]'
          dangerouslySetInnerHTML={{
            __html: promotionPopupData.header.replace(
              new RegExp('\\\\n', 'g'),
              '<br/>'
            ),
          }}
        ></h2>
        {popupType === 'PRODUCT BS' ? (
          <>
            {promotionPopupData.image && (
              <Image
                src={promotionPopupData.image}
                alt=''
                width={0}
                height={0}
                sizes='100vw'
                className='h-6 w-auto max-lg:!mb-1 max-lg:!mt-4'
              />
            )}
          </>
        ) : (
          <span
            className='text-xl font-medium'
            style={{ color: promotionPopupData.color_theme?.header_color }}
            dangerouslySetInnerHTML={{
              __html: promotionPopupData.sub_header?.replace(
                new RegExp('\\\\n', 'g'),
                '<br/>'
              ),
            }}
          ></span>
        )}
      </div>

      <div
        className={clsx(
          'overflow-y-scroll lg:max-h-full',
          'grid grid-cols-2 gap-2 lg:grid-cols-4 lg:gap-4'
        )}
      >
        {products && Array.isArray(products) && products.length > 0
          ? products?.map((product, index) => productHml(product, index))
          : Array(promotionPopupData.product_id_list?.split(',').length)
              .fill(0)
              .map((_, index) => (
                <div
                  className='h-[233px] w-[177px] animate-pulse rounded-lg bg-gradient-to-b from-platin p-2 shadow-sm md:h-[273px] md:w-[216px]'
                  key={`product-promotion-loading-${index}`}
                ></div>
              ))}
      </div>
      <div className='w-full transition-all'>
        <ButtonLink
          href={promotionPopupData.cta_button.link || ''}
          className='!text-custom-base sm:mb-0'
          style={{
            color: promotionPopupData.color_theme?.cta_button_text_color,
            background: promotionPopupData.color_theme?.cta_button_color,
          }}
        >
          {promotionPopupData.cta_button.label}
        </ButtonLink>
      </div>
    </div>
  );
}
