import DiscountModal from 'components/comminucations-modal/discount-modal';
import ProductPromotionModal from 'components/comminucations-modal/product-modal';
import React from 'react';
import { PromotionPopupType } from 'types/cms/promotion-popup';
import { LanguageCodesType, RegionCodesType } from 'utils';

function PromotionPopUp({
  data,
  onCloseModal,
  language,
  region,
  ipAddress,
  dynamicYieldEnable,
}: {
  data: PromotionPopupType;
  onCloseModal?: () => void;
  language: LanguageCodesType;
  region: RegionCodesType;
  ipAddress: string;
  dynamicYieldEnable?: boolean;
}) {
  if (data.popup_type === 'PRODUCT' || data.popup_type === 'PRODUCT BS') {
    return (
      <ProductPromotionModal
        data={data}
        onCloseModal={onCloseModal}
        popupType={data.popup_type}
        language={language}
        region={region}
        ipAddress={ipAddress}
        dynamicYieldEnable={dynamicYieldEnable}
      />
    );
  }

  return (
    <DiscountModal
      data={data}
      onCloseModal={onCloseModal}
      language={language}
      region={region}
    />
  );
}

export default PromotionPopUp;
