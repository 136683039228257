'use client';

import { useEffect, useMemo, useState } from 'react';
import SecondaryOptionModal from 'components/secondary-option-modal';
import { ButtonLink } from 'components/buttons/button-link';
import { PromotionPopupType } from 'types/cms/promotion-popup';
import Image from 'next/image';
import clsx from 'clsx';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function DiscountModal({
  data,
  onCloseModal,
  language,
  region,
}: {
  data: PromotionPopupType;
  onCloseModal?: () => void;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const isGif = useMemo(() => data?.image?.includes('.gif'), [data.image]);
  const [openModal, setOpenModal] = useState(true);
  useEffect(() => {
    if (!openModal && onCloseModal) onCloseModal();
  }, [openModal, onCloseModal]);

  return (
    <SecondaryOptionModal
      modalId='discount-communication-modal'
      open={openModal}
      setOpen={setOpenModal}
      title=''
      backgroundImage={data.background_image}
      backgroundColor={data.color_theme.background_color}
      closeButtonImage={data.close_button}
      contentClassName={`!p-0 ${isGif ? '' : '!rounded-[21px]'}`}
      closeButtonClassName='!top-8'
      containerClassName='!rounded-t-[22px]'
      modalClassname='!items-center'
      language={language}
      region={region}
    >
      {data.image ? (
        <Image
          src={data.image}
          alt=''
          width={0}
          height={0}
          sizes='100vw'
          priority
          className={clsx([
            'no-clip mx-auto',
            isGif ? 'w-[171px] rounded-lg' : 'w-full !rounded-[21px]',
          ])}
        />
      ) : (
        <div className='h-[192px] w-48'></div>
      )}
      <div className={clsx(['p-8', isGif ? 'pt-0' : 'pt-8'])}>
        <div className='w-full'>
          <p
            className='mb-1 text-center text-xl font-semibold md:text-[28px]'
            style={{ color: data.color_theme.header_color }}
            dangerouslySetInnerHTML={{
              __html: data.header.replace(new RegExp('\\\\n', 'g'), '<br>'),
            }}
          ></p>
          <p
            className='mb-4 text-center text-xl font-semibold'
            style={{ color: data.color_theme.sub_header_color }}
            dangerouslySetInnerHTML={{
              __html: data.sub_header.replace(new RegExp('\\\\n', 'g'), '<br>'),
            }}
          ></p>
          <p
            className='text-center text-custom-base'
            style={{ color: data.color_theme.description_color }}
            dangerouslySetInnerHTML={{
              __html: data.description.replace(
                new RegExp('\\\\n', 'g'),
                '<br>'
              ),
            }}
          ></p>
        </div>
        <ButtonLink
          href={data.cta_button.link || ''}
          className='mt-8 !text-custom-base'
          style={{
            background: data.color_theme.cta_button_color,
            color: data.color_theme.cta_button_text_color,
          }}
        >
          {data.cta_button.label}
        </ButtonLink>
      </div>
    </SecondaryOptionModal>
  );
}
