'use client';
import clsx from 'clsx';
import { Underline } from 'components/buttons/underline';
import ExpiresCountdown from 'components/countdown/expires-countdown';
import { ItemOfferType, OfferWeekType } from 'types/cms/offer-of-week';
import OfferBadge from './offer-badge';
import UnlocksCountdown from 'components/countdown/unlocks-countdown';
import { UnlocksIcon } from 'components/icon';
import { ClockAlarmIcon } from 'components/icons';
import { useEffect, useMemo, useState } from 'react';
import { addDurationToDate } from 'hooks/use-countdown';
import {
  CalculateGrid,
  NumberOfItemRendered,
  CalculateColSpan,
  CalculateImageSize,
  CalculateTimerPosition,
  CalculateTextProperties,
  ShowPromoText,
} from './offer-style-functions';
import { gtmSetSelectPromotion } from 'lib/gtm';
import { mobileSize, useMediaQuery } from 'hooks/use-media-query';
import ContentfulLink from 'components/contentful-link';
import { LanguageCodesType, RegionCodesType } from 'utils';
import OfferCarousel from './offer-carousel';
import { Media } from 'components/media';

export default function OfferDealsComponent({
  data,
  language,
  region,
}: {
  data: OfferWeekType;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const isClearanceSale = !!data?.promo_end_date;
  const currentStyle = data?.style ?? 'default';
  const style = CalculateGrid(currentStyle, isClearanceSale);
  const isMobile = useMediaQuery(mobileSize);
  const numberOfItemRendered = NumberOfItemRendered(
    currentStyle,
    isMobile,
    isClearanceSale
  );
  const promoEndDate = useMemo(
    () => new Date(data?.promo_end_date),
    [data?.promo_end_date]
  );
  function handleGtm(categoryItem: ItemOfferType) {
    gtmSetSelectPromotion(categoryItem, 'Clearance Sale');
  }
  const [timeHasCome, setTimeHasCome] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  }, []);
  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      if (now >= promoEndDate) {
        setTimeHasCome(true);
      }
    };
    checkTime();
    const intervalId = setInterval(checkTime, 1000);
    return () => clearInterval(intervalId);
  }, [promoEndDate]);
  if (!isClearanceSale && currentStyle === 'default' && !isMobile) {
    return <OfferCarousel data={data} language={language} region={region} />;
  }

  return (
    <>
      <div
        className={`relative ${
          (data?.color_theme?.background_color ||
            data?.color_theme?.background_image) &&
          'py-[52px] max-lg:py-8'
        }`}
        style={{
          backgroundColor: data?.color_theme?.background_color,
          backgroundImage: data?.color_theme?.background_image,
        }}
      >
        <div className='container-base h-full'>
          {(data.label || data.cta_button) && (
            <div className='relative mb-4 mt-[13px] flex items-center justify-between'>
              <div
                className='flex w-full justify-between gap-4 text-2xl max-sm:text-xl lg:w-auto'
                style={{ color: data.color_theme.header_text_color }}
              >
                <h2 className='overflow-hidden whitespace-nowrap'>
                  {data.header}
                </h2>
                {data.countdown_timer.countdown_timer_style === 'CLOCK' &&
                  data.countdown_timer.countdown_timer_placement ===
                    'DEFAULT' && (
                    <ExpiresCountdown
                      itemId={'main-item-1'}
                      date={addDurationToDate(
                        data.promo_time_remaining ?? '0h00m00'
                      )}
                      showTimer={true}
                      icon={data?.countdown_timer?.icon}
                      countdownProperties={data.countdown_timer}
                      containerClassname={clsx(
                        'text-custom-lg font-semibold h-[30px] w-fit flex-[0_0_auto]'
                      )}
                      childClasses='lg:text-custom-lg text-custom-base font-semibold lg:h-[21px]  h-[18px] w-fit'
                      style={{
                        backgroundColor:
                          data?.color_theme?.countdown_timer_background_color,
                        borderColor:
                          data?.color_theme?.countdown_timer_border_color,
                        color:
                          data?.color_theme?.countdown_timer_label_text_color,
                      }}
                    />
                  )}
              </div>

              {data.cta_button &&
                currentStyle === 'default' &&
                isClearanceSale && (
                  <div className='hidden lg:block'>
                    <Underline
                      href={data.cta_button.link || ''}
                      color={data.color_theme.cta_button_label_text_color}
                    >
                      {data.cta_button.label}
                    </Underline>
                  </div>
                )}
              {data.link && !isClearanceSale && (
                <Underline
                  href={data?.link || ''}
                  color={data?.color_theme.cta_button_label_text_color}
                >
                  {data?.label}
                </Underline>
              )}
            </div>
          )}
          {isLoading ? (
            <div className='h-80 w-full animate-pulse rounded-lg bg-gradient-to-b from-platin p-2 shadow-sm'></div>
          ) : (
            <div className={`grid ${style} place-items-center gap-4`}>
              {data?.items
                ?.slice(0, numberOfItemRendered)
                ?.map((categoryItem: ItemOfferType, index: number) => (
                  <div
                    key={index}
                    onClick={() => handleGtm(categoryItem)}
                    className={clsx(
                      'relative',
                      index >= numberOfItemRendered && 'hidden lg:block',
                      CalculateColSpan(currentStyle, index),
                      CalculateImageSize(currentStyle, index),
                      'flex flex-col'
                    )}
                  >
                    {data.countdown_timer.countdown_timer_style === 'PADLOCK' &&
                    !timeHasCome ? (
                      <>
                        <div className='relative flex h-full w-full flex-col items-center rounded-[10px]'>
                          <Media
                            srcset={{
                              mobileImage: categoryItem?.mobile_image,
                              desktopImage: categoryItem?.web_image,
                              desktopImageWidth: categoryItem?.web_image_width,
                              desktopImageHeight:
                                categoryItem?.web_image_height,
                              mobileImageWidth:
                                categoryItem?.mobile_image_width,
                              mobileImageHeight:
                                categoryItem?.mobile_image_height,
                            }}
                            fill
                            alt={categoryItem.label || 'NAHDI'}
                            className='object-contain'
                            decoding='async'
                            fetchPriority='auto'
                          />
                          <div className='h-full w-full'>
                            {index === 0 ? (
                              <UnlocksCountdown
                                containerClassname='absolute top-0 left-0 w-full h-full justify-center'
                                showTimer={true}
                                date={promoEndDate}
                              />
                            ) : (
                              <div className='flex h-full w-full items-center justify-center'>
                                <div className='z-10 flex h-[50px] w-[50px] items-center justify-center rounded-full bg-white'>
                                  <UnlocksIcon className='h-[30px] w-[20px]' />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='absolute inset-0 rounded-[10px] bg-black opacity-60'></div>
                      </>
                    ) : (
                      <ContentfulLink
                        item={categoryItem}
                        className='h-full w-full'
                        language={language}
                        region={region}
                      >
                        <div
                          className='relative flex h-full w-full flex-col items-center rounded-[10px]'
                          style={{
                            backgroundColor:
                              categoryItem.color_theme?.background_color,
                            backgroundImage: `linear-gradient(to bottom, ${
                              categoryItem?.color_theme?.background_gradient
                                ? categoryItem?.color_theme
                                    ?.background_gradient[0]
                                : ''
                            }, ${
                              categoryItem?.color_theme?.background_gradient
                                ? categoryItem?.color_theme
                                    ?.background_gradient[1]
                                : ''
                            })`,
                          }}
                        >
                          {data.countdown_timer.countdown_timer_style ===
                            'CLOCK' &&
                          data.countdown_timer.countdown_timer_placement ===
                            '1ST ITEM' &&
                          index === 0 ? (
                            <div
                              className={clsx(
                                CalculateTimerPosition(currentStyle),
                                'absolute w-fit lg:left-auto lg:right-3 lg:top-3'
                              )}
                            >
                              <ExpiresCountdown
                                itemId={'main-item-1'}
                                date={addDurationToDate(
                                  data.promo_time_remaining ?? '0h00m00'
                                )}
                                showTimer={true}
                                icon={data?.countdown_timer?.icon}
                                countdownProperties={data.countdown_timer}
                                containerClassname={clsx(
                                  'text-custom-base fomt-semibold h-[30px]'
                                )}
                                childClasses='text-custom-base font-semibold h-[18px] w-[75px]'
                                style={{
                                  backgroundColor:
                                    data?.color_theme
                                      ?.countdown_timer_background_color,
                                  borderColor:
                                    data?.color_theme
                                      ?.countdown_timer_border_color,
                                  color:
                                    data?.color_theme
                                      ?.countdown_timer_label_text_color,
                                }}
                              />
                            </div>
                          ) : (
                            categoryItem.show_countdown_icon &&
                            isClearanceSale && (
                              <div
                                className={clsx(
                                  'absolute right-3 top-3 z-10 flex h-8 w-8 items-center justify-center rounded-full border border-dashed border-red',
                                  data.countdown_timer
                                    .countdown_timer_background_color ??
                                    'bg-red bg-opacity-20'
                                )}
                                style={{
                                  backgroundColor:
                                    categoryItem?.color_theme
                                      ?.countdown_timer_background_color,
                                  borderColor:
                                    categoryItem?.color_theme
                                      ?.countdown_timer_border_color,
                                  color:
                                    categoryItem?.color_theme
                                      ?.countdown_timer_label_text_color,
                                }}
                              >
                                <ClockAlarmIcon className='h-5 w-5' />
                              </div>
                            )
                          )}
                          <div className='relative h-full w-full'>
                            <Media
                              srcset={{
                                mobileImage: categoryItem?.mobile_image,
                                desktopImage: categoryItem?.web_image,
                                desktopImageWidth:
                                  categoryItem?.web_image_width,
                                desktopImageHeight:
                                  categoryItem?.web_image_height,
                                mobileImageWidth:
                                  categoryItem?.mobile_image_width,
                                mobileImageHeight:
                                  categoryItem?.mobile_image_height,
                              }}
                              fill
                              alt={categoryItem.label || 'NAHDI'}
                              className='object-contain'
                              decoding='async'
                              fetchPriority='auto'
                            />
                          </div>

                          <div
                            className={clsx(
                              'flex w-[calc(100%-2rem)] flex-col items-center pb-4 lg:p-0 lg:pb-0',
                              (currentStyle === '1X2 GRID' ||
                                currentStyle === '1 TOP 3 BELOW' ||
                                currentStyle === '1 TOP 2 BELOW') &&
                                'w-full items-center justify-between',
                              currentStyle === '1 FULL' &&
                                'w-full items-center justify-center'
                            )}
                          >
                            <div
                              className={clsx(
                                'flex w-full',
                                currentStyle === '2X2 RECTANGLE' ||
                                  currentStyle === 'default'
                                  ? 'justify-center'
                                  : 'justify-start'
                              )}
                            >
                              {CalculateTextProperties(
                                categoryItem,
                                index,
                                true,
                                currentStyle,
                                isClearanceSale
                              )}
                            </div>

                            <div
                              className={clsx(
                                ShowPromoText(currentStyle)
                                  ? 'flex'
                                  : 'hidden lg:flex',
                                'w-full justify-center'
                              )}
                            >
                              {categoryItem.promo_badge_text ||
                              categoryItem.promo_badge_sub_text ? (
                                <OfferBadge
                                  promo_badge_text={
                                    categoryItem.promo_badge_text
                                  }
                                  promo_badge_style={
                                    categoryItem.promo_badge_style
                                  }
                                  promo_badge_text_color={
                                    categoryItem.color_theme
                                      ?.promo_badge_text_color
                                  }
                                  promo_badge_line_color={
                                    categoryItem.color_theme
                                      ?.promo_badge_line_color
                                  }
                                  promo_badge_icon={
                                    categoryItem.color_theme?.promo_badge_icon
                                  }
                                  promo_badge_background_color={
                                    categoryItem.color_theme
                                      ?.promo_badge_background_color
                                  }
                                  promo_badge_sub_text={
                                    categoryItem.promo_badge_sub_text
                                  }
                                  custom_badge_style={`m-0 ${
                                    currentStyle === '1 TOP 3 BELOW' ||
                                    currentStyle === '1 TOP 2 BELOW'
                                      ? '!text-custom-2xs !truncate line-clamp-1'
                                      : ''
                                  }`}
                                />
                              ) : (
                                <div className='h-6 w-full lg:h-12'></div>
                              )}
                            </div>
                          </div>
                        </div>
                      </ContentfulLink>
                    )}
                    {data.countdown_timer.countdown_timer_style !== 'PADLOCK' &&
                      !timeHasCome && (
                        <div
                          className={clsx(
                            'flex w-full',
                            currentStyle === '2X2 RECTANGLE'
                              ? 'justify-center'
                              : 'justify-start'
                          )}
                        >
                          {CalculateTextProperties(
                            categoryItem,
                            index,
                            false,
                            currentStyle
                          )}
                        </div>
                      )}
                  </div>
                ))}
            </div>
          )}
          {data.cta_button && isClearanceSale && (
            <button
              className={
                'mt-4 inline-flex h-10 w-full items-center justify-center gap-2.5 rounded-md bg-blue px-6 py-3 text-center text-custom-sm text-white lg:hidden'
              }
              style={{
                backgroundColor: data?.color_theme?.cta_button_background_color,
                color: data?.color_theme?.cta_button_label_text_color,
              }}
            >
              {data.cta_button.label}
            </button>
          )}
        </div>
      </div>
      <div className='hidden 2xl:grid-cols-6'></div>
    </>
  );
}
