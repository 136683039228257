'use client';
import React from 'react';
import { HomePageProductCarouselType } from 'types/cms/product-carousel';
import { useGetExperienceProducts } from 'hooks/use-experience';

import ProductCarousel from 'components/cms/banners/product-carousel-banner/product-carousel';
import { disableGlobalConfig } from 'utils/disable-global-config';
import { useAppSelector } from 'redux/hooks';
import { LanguageCodesType, RegionCodesType } from 'utils';

function DyProductCarousel({
  language,
  region,
  data,
  ipAddress,
  componentIndex,
  zoneId,
}: {
  language: LanguageCodesType;
  region: RegionCodesType;
  data: HomePageProductCarouselType;
  ipAddress: string;
  componentIndex: number;
  zoneId: string | undefined;
}) {
  const { appSettings } = useAppSelector((state) => state.cmsReducer);
  const dynamicYieldEnable = disableGlobalConfig({
    settings: appSettings?.functionalityConfig?.dynamicYieldSettings,
  });

  const [products] = useGetExperienceProducts(
    data.product_id_list,
    dynamicYieldEnable ? data.campaign_id : '',
    ipAddress,
    zoneId,
    language,
    region,
    dynamicYieldEnable
  );
  return (
    <ProductCarousel
      algoliaType={false}
      products={products!}
      availableOnly={data?.available_only}
      fadeLast={data?.color_theme?.slider_fade_effect}
      componentIndex={componentIndex}
      swiper
      language={language}
      region={region}
    />
  );
}

export default DyProductCarousel;
