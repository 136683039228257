import ContentfulLink from 'components/contentful-link';
import React from 'react';
import { Widget } from './model';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { Media } from 'components/media';

const styles = {
  '4-words': 'grid grid-cols-2 gap-2 md:gap-4',
  '6-words': 'grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4',
  '8-words': 'grid grid-cols-2 gap-2 md:gap-4',
};

function TrendingSearches({
  widget: { color_theme, header, items, style, image },
  language,
  region,
}: {
  widget: Widget;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const getType = () => {
    if (style == '4 KEYWORDS') return '4-words';
    if (style == '6 KEYWORDS') return '6-words';
    return '8-words';
  };
  const length = +style.charAt(0) ?? 8;
  const backgroundGradient =
    color_theme.background_colors.length >= 2
      ? `linear-gradient(111deg, ${color_theme.background_colors[0]} -0.05%, ${color_theme.background_colors[1]} 99.95%)`
      : 'none';

  return (
    <div
      style={{
        background: backgroundGradient,
      }}
      className='flex flex-col items-center gap-4 md:gap-8'
    >
      <div className='container-base flex flex-col gap-4 overflow-hidden pb-8 md:gap-8 lg:pb-[52px]'>
        <Media
          src={image}
          alt={header}
          className='mx-auto -mb-10 h-[75px] w-[75px] md:h-[100px] md:w-[100px]'
        />
        <span className='mx-auto mt-6 h-[24px] max-w-[100%] overflow-hidden break-all text-center text-[20px] md:h-[30px] md:text-[24px] lg:mt-2'>
          {header}
        </span>
        <div className={`${styles[getType()]} mx-auto`}>
          {items.slice(0, length).map((item, index) => (
            <ContentfulLink
              className='flex overflow-hidden md:w-[288px]'
              item={item}
              key={item.link + index}
              language={language}
              region={region}
            >
              <Media
                src={item.image}
                className='h-[56px] w-[56px] ltr:rounded-l-[10px] rtl:rounded-r-[10px]'
                alt={item.label + index}
              />
              <div
                style={{
                  backgroundColor: color_theme.search_item_background_color,
                  color: color_theme.search_item_label_color,
                }}
                className='flex w-full items-center overflow-hidden px-3 text-custom-sm md:px-4 md:text-custom-base ltr:rounded-r-[10px] rtl:rounded-l-[10px]'
              >
                <span className='h-[16px] overflow-hidden break-all md:h-[18px] md:w-[288px]'>
                  {item.label}
                </span>
              </div>
            </ContentfulLink>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TrendingSearches;
