'use client';
import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import { ItemOfferType, ItemColorThemeType } from 'types/cms/offer-of-week';

function OfferBadge({
  promo_badge_text,
  promo_badge_style,
  promo_badge_text_color,
  promo_badge_line_color,
  promo_badge_background_color,
  promo_badge_icon,
  promo_badge_sub_text,
  custom_badge_style,
}: ItemColorThemeType & ItemOfferType) {
  const [badgeIndex, setBadgeIndex] = useState(0);
  const [badges, setBadges] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const badges = [];
    if (promo_badge_text) {
      badges.push(promo_badge_text);
    }
    if (promo_badge_sub_text) {
      badges.push(promo_badge_sub_text);
    }
    setBadges(badges);
  }, [promo_badge_sub_text, promo_badge_text]);

  const updateBadge = useCallback(() => {
    if (promo_badge_sub_text) {
      setBadgeIndex((value) => value + 1);
    } else {
      setBadgeIndex(0);
    }
  }, [promo_badge_sub_text]);

  useEffect(() => {
    if (promo_badge_sub_text) {
      const intervalId = setInterval(updateBadge, 2000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [updateBadge, promo_badge_sub_text]);
  useEffect(() => {
    setIsLoading(false);
  }, []);

  const badge_style = (item: string | null) => {
    if (item === 'SOLID') {
      return 'bg-red text-white';
    } else if (item === 'LINE') {
      return 'border-dashed bg-[#e9aeae] text-red';
    }
  };
  return (
    <>
      {promo_badge_text || promo_badge_sub_text ? (
        <div
          className={clsx(
            'max-w-[calc(100%-1rem)] space-x-2 rounded-2xl border-2 border-red px-2 py-0.5 text-custom-xs font-bold lg:mb-4 lg:mt-2 2xl:text-custom-sm rtl:space-x-reverse',
            custom_badge_style,
            badge_style(promo_badge_style || 'SOLID')
          )}
          style={{
            color: promo_badge_text_color,
            backgroundColor: promo_badge_background_color,
            borderColor: promo_badge_line_color,
          }}
        >
          <p className='animate-fade-in-out flex items-center gap-2'>
            {promo_badge_icon && (
              <Image
                src={promo_badge_icon}
                alt={promo_badge_icon}
                width='0'
                height='0'
                style={{ width: 'auto', height: '16px' }}
              />
            )}
            {isLoading ? (
              <span className='h-4 w-12 animate-pulse rounded-lg bg-gradient-to-b from-platin to-white text-transparent shadow-sm'></span>
            ) : (
              <span className='overflow-hidden whitespace-nowrap'>
                {badges[badgeIndex % badges.length]}
              </span>
            )}
          </p>
        </div>
      ) : null}
    </>
  );
}

export default OfferBadge;
