import { getProductsByProductIds } from 'lib/api';
import { DyRequestBodyType } from 'lib/nahdi-middleware/experience';
import { SetStateAction, useEffect, useMemo, useState, Dispatch } from 'react';
import { useCookies } from 'react-cookie';
import { ProductItemType } from 'types/cms/product-carousel';
import { getExperienceDataSetCookie } from 'utils/experience';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { COOKIE_DY_ID_SERVER, COOKIE_DY_SESSION } from 'utils/constant-cookies';

export function useGetExperienceBody(
  _dyid_server: string,
  _dyjsession: string,
  campaign_id: string,
  language: LanguageCodesType,
  region: RegionCodesType,
  ipAddress: string,
  dynamicYieldEnable?: boolean
) {
  let windowVariable: any = null;
  let navigatorVariable: any = null;
  if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
    windowVariable = window;
    navigatorVariable = navigator;
  }
  const locale = `${language}_${region}`;
  const requestBody = useMemo(() => {
    // if the pathname includes only one /, it means the homepage, ex: /en, /ar
    const bodyPageType =
      ((windowVariable && windowVariable.location.pathname.match(/\//g)) || [])
        .length === 1
        ? 'HOMEPAGE'
        : 'OTHER';

    return {
      user: {
        dyid: dynamicYieldEnable ? _dyid_server?.toString() || '' : '',
        dyid_server: dynamicYieldEnable ? _dyid_server?.toString() || '' : '',
        active_consent_accepted: true,
        sharedDevice: false,
      },
      session: {
        dy: _dyjsession || '',
      },
      selector: {
        names: [campaign_id],
      },
      context: {
        page: {
          type: bodyPageType,
          data: [],
          location: windowVariable ? windowVariable.location.origin : '',
          referrer: '',
          locale,
        },
        device: {
          user_agent: navigatorVariable ? navigatorVariable.userAgent : '',
          ip: ipAddress,
          browser: '',
          date_time: new Date().toISOString(),
        },
        cart: {
          innerProducts: [
            // TODO: This data will be dynamic when the cart is added
            {
              quantity: 1,
              itemPrice: 35.2,
              productId: '3112as',
            },
          ],
        },
        channel: 'WEB',
      },
      options: {
        is_implicit_pageview: _dyjsession ? false : true,
        recsProductData: {
          skusOnly: true,
        },
      },
    };
  }, [
    _dyid_server,
    _dyjsession,
    campaign_id,
    ipAddress,
    locale,
    navigatorVariable,
    windowVariable,
    dynamicYieldEnable,
  ]);

  return requestBody;
}

export const useGetExperienceProducts = (
  productIdList: string,
  campaignId: string,
  ipAddress: string,
  zoneId: string | undefined,
  language: LanguageCodesType,
  region: RegionCodesType,
  dynamicYieldEnable?: boolean
): [
  ProductItemType[] | undefined,
  Dispatch<SetStateAction<ProductItemType[] | undefined>>,
] => {
  const [products, setProducts] = useState<ProductItemType[] | undefined>(
    undefined
  );
  const [cookies, setCookie] = useCookies([
    COOKIE_DY_SESSION,
    COOKIE_DY_ID_SERVER,
  ]);
  const requestBody = useGetExperienceBody(
    cookies._dyid_server,
    cookies._dyjsession,
    campaignId,
    language,
    region,
    ipAddress,
    dynamicYieldEnable
  );
  useEffect(() => {
    getProductExperienceData(
      requestBody,
      setProducts,
      productIdList,
      campaignId,
      zoneId,
      language,
      region
    );
  }, [
    campaignId,
    language,
    productIdList,
    region,
    requestBody,
    setCookie,
    zoneId,
  ]);

  return [products, setProducts];
};

export const getProductExperienceData = async (
  requestBody: DyRequestBodyType,
  setProducts: any,
  productIdList: string,
  campaignId: string,
  zoneId: string | undefined,
  language: LanguageCodesType,
  region: RegionCodesType
) => {
  const apiData = await getExperienceDataSetCookie(
    language,
    region,
    requestBody
  );
  let products;

  apiData?.choices?.forEach((d: any) => {
    if (d.products?.length && d.campaign_id === campaignId) {
      products = d.products;
    }
  });

  if (!products && productIdList) {
    products = await getProductsByProductIds(
      productIdList,
      language,
      region,
      zoneId
    );
  }

  setProducts(products);
};
