'use client';

import clsx from 'clsx';
import { Button } from 'components/buttons/button';
import BaseSwiper from 'components/swiper/base-swiper';
import ContentfulLink from 'components/contentful-link';
import React, { CSSProperties, useEffect, useState } from 'react';
import {
  SizeOptions,
  VendorBannerOptionType,
  VendorBannerPropsType,
  VendorBannerSizeType,
} from 'types/cms/vendor-banner';
import { gtmSetViewPromotion } from 'lib/gtm';
import { LanguageCodesType, RegionCodesType } from 'utils';
import { Media } from 'components/media';

const sizes: {
  [sizeOption in VendorBannerSizeType]: {
    [itemOption in VendorBannerOptionType]: SizeOptions;
  };
} = {
  large: {
    single: {
      className: 'h-[156px] md:h-[340px]',
      breakpoints: {
        350: {
          slidesPerView: 1,
        },
      },
      contentAreaClassname:
        'md:max-w-[500px] max-w-[180px] ltr:pl-4 ltr:md:pl-8 rtl:pr-4 rtl:md:pr-8 md:gap-4 gap-3',
      headingClassname:
        'text-[17px] md:text-[28px] max-h-[42px] md:max-h-[58px]',
      contentTextLength: 52,
      contentTextLengthMobile: 36,
      ctaButtonTextLength: 19,
      ctaButtonTextLengthMobile: 19,
    },
    two: {
      className: 'h-[130px] md:h-[255px]',
      breakpoints: {
        350: {
          slidesPerView: 1.16,
        },
        768: {
          slidesPerView: 1.63,
        },
        1024: {
          slidesPerView: 2,
        },
      },
      contentAreaClassname:
        'md:max-w-[275px] max-w-[150px] ltr:pl-4 ltr:md:pl-8 rtl:pr-4 rtl:md:pr-8 md:gap-4 gap-3',
      headingClassname:
        'text-[17px] md:text-[24px] max-h-[42px] md:max-h-[58px]',
      contentTextLength: 30,
      contentTextLengthMobile: 30,
      ctaButtonTextLength: 19,
      ctaButtonTextLengthMobile: 19,
    },
    multi: {
      className: 'h-[130px] md:h-[223px]',
      breakpoints: {
        350: {
          slidesPerView: 1.16,
        },
        768: {
          slidesPerView: 1.63,
        },
        1024: {
          slidesPerView: 2.31,
        },
      },
      contentAreaClassname:
        'md:max-w-[260px] max-w-[150px] ltr:pl-4 ltr:md:pl-8 rtl:pr-4 rtl:md:pr-8 md:gap-4 gap-3',
      headingClassname:
        'text-[17px] md:text-[24px] max-h-[42px] md:max-h-[58px]',
      contentTextLength: 30,
      contentTextLengthMobile: 30,
      ctaButtonTextLength: 19,
      ctaButtonTextLengthMobile: 19,
    },
  },
  medium: {
    single: {
      className: 'h-[100px] md:h-[200px]',
      breakpoints: {
        350: {
          slidesPerView: 1,
        },
      },
      contentAreaClassname:
        'md:max-w-[500px] max-w-[180px] ltr:pl-4 ltr:md:pl-8 rtl:pr-4 rtl:md:pr-8 md:gap-4 gap-2',
      headingClassname:
        'text-[15px] md:text-[28px] max-h-[42px] md:max-h-[58px]',
      contentTextLength: 36,
      contentTextLengthMobile: 36,
      ctaButtonTextLength: 19,
      ctaButtonTextLengthMobile: 19,
    },
    two: {
      className: 'h-[100px] md:h-[164px]',
      breakpoints: {
        350: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1.47,
        },
        1024: {
          slidesPerView: 2,
        },
      },
      contentAreaClassname:
        'md:max-w-[280px] max-w-[180px] ltr:pl-4 ltr:md:pl-8 rtl:pr-4 rtl:md:pr-8 md:gap-4 gap-2',
      headingClassname:
        'text-[15px] md:text-[24px] max-h-[42px] md:max-h-[58px]',
      contentTextLength: 36,
      contentTextLengthMobile: 36,
      ctaButtonTextLength: 19,
      ctaButtonTextLengthMobile: 19,
    },
    multi: {
      className: 'h-[100px] md:h-[157px]',
      breakpoints: {
        350: {
          slidesPerView: 1.03,
        },
        768: {
          slidesPerView: 1.47,
        },
        1024: {
          slidesPerView: 2.07,
        },
      },
      contentAreaClassname:
        'md:max-w-[260px] max-w-[170px] ltr:pl-4 ltr:md:pl-8 rtl:pr-4 rtl:md:pr-8 md:gap-3 gap-2',
      headingClassname:
        'text-[17px] md:text-[24px] max-h-[42px] md:max-h-[58px]',
      contentTextLength: 36,
      contentTextLengthMobile: 36,
      ctaButtonTextLength: 19,
      ctaButtonTextLengthMobile: 19,
    },
  },
  twoBanner: {
    single: {
      className: 'h-[200px] md:h-[255px]',
      breakpoints: {
        350: {
          slidesPerView: 1,
        },
      },
    },
    two: {
      className: 'h-[200px] md:h-[255px]',
      breakpoints: {
        350: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1.62,
        },
        1024: {
          slidesPerView: 2,
        },
      },
      contentAreaClassname:
        'md:max-w-[260px] w-full ltr:px-[12.5px] md:text-left text-center ltr:md:pl-8 rtl:px-[12.5px] rtl:md:pr-8 md:gap-4 gap-0',
      headingClassname:
        'text-[20px] md:text-[28px] max-h-[50px] md:max-h-[58px]',
      contentTextLength: 36,
      contentTextLengthMobile: 26,
      ctaButtonTextLength: 24,
      ctaButtonTextLengthMobile: 13,
    },
    multi: {
      className: 'h-[200px] md:h-[223px]',
      breakpoints: {
        350: {
          slidesPerView: 2.3,
        },
        768: {
          slidesPerView: 1.62,
        },
        1024: {
          slidesPerView: 2.27,
        },
      },
      contentAreaClassname:
        'md:max-w-[260px] w-full ltr:px-[12.5px] md:pt-0 py-4 md:text-left text-center ltr:md:pl-8 rtl:px-[12.5px] rtl:md:pr-8 md:gap-4 gap-0',
      headingClassname:
        'text-[20px] md:text-[24px] max-h-[50px] md:max-h-[58px]',
      contentTextLength: 36,
      contentTextLengthMobile: 26,
      ctaButtonTextLength: 24,
      ctaButtonTextLengthMobile: 13,
    },
  },
};

const contentfulTypeToSizes = (data: VendorBannerPropsType) => {
  const fallbackSize = 'medium'; // TODO: settle the fallback value in case of adding new types.

  if (data.banner_variation_type === 'INFO') {
    return 'medium';
  } else if (data.banner_variation_type === 'MARKETING') {
    return 'large';
  } else if (data.banner_variation_type === 'COMMERCIAL') {
    return 'twoBanner';
  } else {
    return fallbackSize;
  }
};

function VendorBanner({
  data,
  componentIndex,
  customContainerWidth,
  customStyle,
  language,
  region,
}: {
  data: VendorBannerPropsType;
  componentIndex: number;
  customContainerWidth?: string;
  customStyle?: CSSProperties;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const [isMobile, setIsMobile] = useState(false);
  if (!data) return null;

  const getItemType = (): VendorBannerOptionType => {
    if (data.items?.length === 1) return 'single';
    if (data.items?.length === 2) return 'two';
    return 'multi';
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 768);
    }
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    gtmSetViewPromotion(data, 'Vendor Banner');
  }, [data]);

  return (
    <div
      className='w-full'
      style={{ background: data?.color_theme?.background_color }}
    >
      <div
        className={clsx([
          customContainerWidth,
          'md:container-base',
          'ltr:pl-4 ltr:md:pl-0 rtl:pr-4 rtl:md:pr-0',
          data.color_theme?.background_color && 'py-8 md:py-12',
        ])}
        style={customStyle}
      >
        <div className={clsx(['relative w-full'])}>
          <BaseSwiper
            swiperProps={{
              navigation: {
                nextEl: `.custom-${componentIndex}-vendor-right-button`,
                prevEl: `.custom-${componentIndex}-vendor-left-button`,
                disabledClass: 'swiper-button-disabled',
              },
              breakpoints:
                sizes[contentfulTypeToSizes(data)][getItemType()].breakpoints,
              spaceBetween: isMobile ? '8' : '16',
              fadelast: isMobile ? 'hide' : 'show',
            }}
          >
            {data.items?.map((bannerItem, index) => (
              <div
                key={'vendor-banner-' + index}
                className={clsx([
                  sizes[contentfulTypeToSizes(data)][getItemType()].className,
                  'relative',
                ])}
              >
                <ContentfulLink
                  item={bannerItem}
                  language={language}
                  region={region}
                >
                  <Media
                    srcset={{
                      mobileImage: bannerItem?.mobile_image,
                      desktopImage: bannerItem?.web_image,
                      desktopImageWidth: bannerItem?.web_image_width,
                      desktopImageHeight: bannerItem?.web_image_width,
                      mobileImageWidth: bannerItem?.mobile_image_width,
                      mobileImageHeight: bannerItem?.mobile_image_height,
                    }}
                    alt={bannerItem.description || 'NAHDI'}
                    className='swiper-lazy absolute inset-0 h-full w-full rounded-2xl max-lg:hidden'
                    fetchPriority={index === 0 ? 'high' : 'low'}
                  />
                  <div className='swiper-lazy-preloader'></div>
                  {(bannerItem.header || bannerItem.cta_button?.label) && (
                    <div
                      className={clsx([
                        sizes[contentfulTypeToSizes(data)][getItemType()]
                          .contentAreaClassname,
                        contentfulTypeToSizes(data) === 'twoBanner'
                          ? 'items-center justify-between md:items-start md:justify-center md:py-0 ltr:md:pl-3 rtl:md:pr-3'
                          : 'items-start justify-center',
                        'z-[1] flex h-full flex-col',
                      ])}
                    >
                      {bannerItem.header && (
                        <span
                          className={clsx([
                            sizes[contentfulTypeToSizes(data)][getItemType()]
                              .headingClassname,
                            'z-[1] overflow-hidden break-words leading-tight tracking-tight text-gray-dark',
                          ])}
                          style={{
                            color: bannerItem.color_theme?.header_text_color,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: isMobile
                              ? bannerItem.header.length >
                                sizes[contentfulTypeToSizes(data)][
                                  getItemType()
                                ]?.contentTextLengthMobile!
                                ? bannerItem.header.slice(
                                    0,
                                    sizes[contentfulTypeToSizes(data)][
                                      getItemType()
                                    ].contentTextLengthMobile
                                  )
                                : bannerItem.header
                              : bannerItem.header.length >
                                sizes[contentfulTypeToSizes(data)][
                                  getItemType()
                                ]?.contentTextLength!
                              ? bannerItem.header.slice(
                                  0,
                                  sizes[contentfulTypeToSizes(data)][
                                    getItemType()
                                  ].contentTextLength
                                )
                              : bannerItem.header,
                          }}
                        />
                      )}
                      {bannerItem.cta_button && (
                        <>
                          <Button
                            className='mix-w-[99px] hidden !w-auto break-all !p-2 !text-custom-xs font-semibold text-white max-md:leading-tight md:block'
                            style={{
                              background:
                                bannerItem.color_theme
                                  ?.cta_button_background_color,
                              color:
                                bannerItem.color_theme
                                  ?.cta_button_label_text_color,
                            }}
                          >
                            {bannerItem?.cta_button.label?.length! >
                            sizes[contentfulTypeToSizes(data)][getItemType()]
                              ?.ctaButtonTextLength!
                              ? bannerItem?.cta_button.label?.slice(
                                  0,
                                  sizes[contentfulTypeToSizes(data)][
                                    getItemType()
                                  ].ctaButtonTextLength
                                )
                              : bannerItem.cta_button.label}
                          </Button>
                          <Button
                            className='mix-w-[99px] block !w-auto break-all !p-2 !text-custom-xs font-semibold text-white max-md:leading-tight md:hidden'
                            style={{
                              background:
                                bannerItem.color_theme
                                  ?.cta_button_background_color,
                              color:
                                bannerItem.color_theme
                                  ?.cta_button_label_text_color,
                            }}
                          >
                            {bannerItem?.cta_button.label?.length! >
                            sizes[contentfulTypeToSizes(data)][getItemType()]
                              ?.ctaButtonTextLengthMobile!
                              ? bannerItem?.cta_button.label?.slice(
                                  0,
                                  sizes[contentfulTypeToSizes(data)][
                                    getItemType()
                                  ].ctaButtonTextLengthMobile
                                )
                              : bannerItem.cta_button.label}
                          </Button>
                        </>
                      )}
                    </div>
                  )}
                </ContentfulLink>
              </div>
            ))}
          </BaseSwiper>
          {data?.items?.length && data.items.length > 2 && (
            <>
              <button
                className={clsx(
                  `custom-${componentIndex}-vendor-left-button swiper-button-prev custom-swiper-button-prev`
                )}
              ></button>
              <button
                className={clsx(
                  `custom-${componentIndex}-vendor-right-button swiper-button-next custom-swiper-button-next`
                )}
              ></button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default VendorBanner;
