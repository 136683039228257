'use client';

import React, { ReactNode, useEffect, useState } from 'react';
import TrendingSearches from './trending-searches';
import { Trending } from './model';
import { LanguageCodesType, RegionCodesType } from 'utils';

interface TrendingProps {
  data: Trending;
  loadingSkeleton?: ReactNode;
  language: LanguageCodesType;
  region: RegionCodesType;
}
function TrendingSearchesPage({
  data,
  loadingSkeleton = (
    <div className='h-[90px] animate-pulse rounded-lg bg-gradient-to-b from-platin p-2 shadow-sm lg:h-[100px] ltr:mr-4 rtl:ml-4'></div>
  ),
  language,
  region,
}: TrendingProps) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  if (!data?.data?.widgets?.length || data.data.widgets.length <= 0) {
    return null;
  }

  return (
    <>
      {loading ? (
        loadingSkeleton
      ) : (
        <TrendingSearches
          widget={data.data.widgets[0]!}
          language={language}
          region={region}
        />
      )}
    </>
  );
}

export default TrendingSearchesPage;
