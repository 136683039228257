'use client';
import clsx from 'clsx';
import ExpiresCountdown from 'components/countdown/expires-countdown';
import { ItemOfferType, OfferWeekType } from 'types/cms/offer-of-week';
import Image from 'next/image';
import UnlocksCountdown from 'components/countdown/unlocks-countdown';
import { UnlocksIcon } from 'components/icon';
import { ClockAlarmIcon } from 'components/icons';
import { useEffect, useMemo, useState } from 'react';
import { addDurationToDate } from 'hooks/use-countdown';
import {
  CalculateTimerPosition,
  ShowPromoText,
} from '../banners/offer/offer-style-functions';
import OfferBadge from '../banners/offer/offer-badge';
import {
  CalculateGridMultiComponent,
  CalculateImageSizeMultiComponent,
  NumberOfItemRenderedMultiComponent,
} from './style-functions';
import ContentfulLink from 'components/contentful-link';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function SingleOfferHandler({
  data,
  componentProperties,
  isScaled,
  language,
  region,
}: {
  data: OfferWeekType;
  componentProperties?: { style: string };
  isScaled?: boolean;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const currentStyle = data.style;
  if (!componentProperties?.style) {
    componentProperties = { style: '3x4' };
  }
  const style = CalculateGridMultiComponent(componentProperties?.style);
  const numberOfItemRendered = NumberOfItemRenderedMultiComponent(
    componentProperties?.style
  );
  const promoEndDate = useMemo(
    () => new Date(data.promo_end_date),
    [data.promo_end_date]
  );

  const [timeHasCome, setTimeHasCome] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (data?.items) {
      setIsLoading(false);
    }
  }, [data?.items]);
  /*  
   const targetRef = useRef(null);
  const [detected, setDetected] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry?.isIntersecting) {
          setDetected(true);
        } else {
          setDetected(false);
        }
      },
      { threshold: 0.5 }
    );
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
    // Cleanup
    return () => {
      if (targetRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(targetRef.current);
      }
    };
  }, []); */
  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      if (now >= promoEndDate) {
        setTimeHasCome(true);
      }
    };
    checkTime();
    const intervalId = setInterval(checkTime, 1000);
    return () => clearInterval(intervalId);
  }, [promoEndDate]);
  if (data?.items == undefined) {
    return null;
  }
  return (
    <div
      className='relative rounded-[10px] p-4'
      style={{
        backgroundColor: data?.color_theme?.background_color,
        backgroundImage: data?.color_theme?.background_image,
      }}
    >
      {(data.header || data.countdown_timer.countdown_timer_style) && (
        <div className='relative mb-4 flex justify-between'>
          <div
            className='flex w-full justify-between gap-4 text-2xl max-sm:text-xl'
            style={{ color: data.color_theme.header_text_color }}
          >
            <h3 className='overflow-hidden whitespace-nowrap'>{data.header}</h3>
            {data.countdown_timer.countdown_timer_style === 'CLOCK' &&
              data.countdown_timer.countdown_timer_placement === 'DEFAULT' && (
                <ExpiresCountdown
                  itemId={'main-item-1'}
                  date={addDurationToDate(data.promo_time_remaining as string)}
                  showTimer={true}
                  icon={data?.countdown_timer?.icon}
                  countdownProperties={data.countdown_timer}
                  containerClassname={clsx(
                    'text-custom-lg fomt-semibold h-[30px]'
                  )}
                  style={{
                    backgroundColor:
                      data?.color_theme?.countdown_timer_background_color,
                    borderColor:
                      data?.color_theme?.countdown_timer_border_color,
                    color: data?.color_theme?.countdown_timer_label_text_color,
                  }}
                />
              )}
          </div>
        </div>
      )}
      {isLoading ? (
        <div className='h-80 w-full rounded-lg bg-gradient-to-b from-platin p-2 shadow-sm'></div>
      ) : (
        <div className={`grid ${style} place-items-start gap-4`}>
          {data?.items
            .slice(0, 6)
            .map((categoryItem: ItemOfferType, index: number) => (
              <div
                key={index}
                className={`${
                  index >= numberOfItemRendered ? 'block lg:hidden' : ''
                } col-span col-span-1 ${CalculateImageSizeMultiComponent(
                  componentProperties?.style
                )}`}
              >
                {data.countdown_timer.countdown_timer_style === 'PADLOCK' &&
                !timeHasCome ? (
                  <div className='relative flex h-full w-full flex-col items-center rounded-[10px] bg-[#D9D9D9]'>
                    <div className='h-full w-full'>
                      {index === 0 ? (
                        <UnlocksCountdown
                          containerClassname='absolute top-0 left-0 w-full h-full justify-center'
                          date={promoEndDate}
                          scale={isScaled}
                        />
                      ) : (
                        <div className='flex h-full w-full items-center justify-center'>
                          <div className='flex h-[50px] w-[50px] items-center justify-center rounded-full bg-white'>
                            <UnlocksIcon className='h-[30px] w-[20px]' />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <ContentfulLink
                    item={categoryItem}
                    language={language}
                    region={region}
                  >
                    <div
                      className='relative flex h-full w-full flex-col items-center rounded-[10px]'
                      style={{
                        backgroundColor:
                          categoryItem.color_theme?.background_color,
                        backgroundImage: `linear-gradient(to bottom, ${
                          categoryItem?.color_theme?.background_gradient
                            ? categoryItem?.color_theme?.background_gradient[0]
                            : ''
                        }, ${
                          categoryItem?.color_theme?.background_gradient
                            ? categoryItem?.color_theme?.background_gradient[1]
                            : ''
                        })`,
                      }}
                    >
                      {data.countdown_timer.countdown_timer_style === 'CLOCK' &&
                      data.countdown_timer.countdown_timer_placement ===
                        '1ST ITEM' &&
                      index === 0 ? (
                        <div
                          className={clsx(
                            CalculateTimerPosition(currentStyle),
                            'absolute w-fit lg:left-auto lg:right-3 lg:top-3'
                          )}
                        >
                          <ExpiresCountdown
                            itemId={'main-item-1'}
                            date={addDurationToDate(
                              data.promo_time_remaining as string
                            )}
                            showTimer={true}
                            countdownProperties={data.countdown_timer}
                            containerClassname={clsx(
                              'text-custom-base fomt-semibold h-[30px] whitespace-nowrap overflow-hidden w-auto'
                            )}
                            style={{
                              backgroundColor:
                                data?.color_theme
                                  ?.countdown_timer_background_color,
                              borderColor:
                                data?.color_theme?.countdown_timer_border_color,
                              color:
                                data?.color_theme
                                  ?.countdown_timer_label_text_color,
                            }}
                          />
                        </div>
                      ) : (
                        categoryItem.show_countdown_icon && (
                          <div
                            className='absolute right-3 top-3 z-10 flex h-8 w-8 items-center justify-center rounded-full border border-dashed'
                            style={{
                              backgroundColor:
                                data?.color_theme
                                  ?.countdown_timer_background_color,
                              borderColor:
                                data?.color_theme?.countdown_timer_border_color,
                              color:
                                data?.color_theme
                                  ?.countdown_timer_label_text_color,
                            }}
                          >
                            <ClockAlarmIcon className='h-5 w-5' />
                          </div>
                        )
                      )}
                      <div className='relative h-full w-full'>
                        {categoryItem.mobile_image && (
                          <Image
                            src={categoryItem.mobile_image}
                            alt={categoryItem?.label || ''}
                            fill
                            style={{ objectFit: 'contain' }}
                            sizes='100vw'
                            className='lg:hidden'
                          />
                        )}
                        {categoryItem?.web_image && (
                          <Image
                            src={categoryItem.web_image}
                            alt={categoryItem?.label || ''}
                            fill
                            style={{ objectFit: 'contain' }}
                            sizes='100vw'
                            className='hidden lg:block'
                          />
                        )}
                      </div>

                      <div
                        className={clsx(
                          'flex w-full flex-col items-center pb-4 lg:p-0 lg:pb-0',
                          (currentStyle === '1X2 GRID' ||
                            currentStyle === '1 TOP 3 BELOW' ||
                            currentStyle === '1 TOP 2 BELOW') &&
                            'w-full items-center justify-between p-4',
                          currentStyle === '1 FULL' &&
                            'w-full items-center justify-center p-4'
                        )}
                      >
                        <div
                          className={clsx(
                            ShowPromoText(currentStyle)
                              ? 'flex'
                              : 'hidden md:flex',
                            'w-full justify-center'
                          )}
                        >
                          {categoryItem.promo_badge_text ||
                          categoryItem.promo_badge_sub_text ? (
                            <OfferBadge
                              promo_badge_text={categoryItem.promo_badge_text}
                              promo_badge_style={categoryItem.promo_badge_style}
                              promo_badge_text_color={
                                categoryItem.color_theme?.promo_badge_text_color
                              }
                              promo_badge_line_color={
                                categoryItem.color_theme?.promo_badge_line_color
                              }
                              promo_badge_icon={
                                categoryItem.color_theme?.promo_badge_icon
                              }
                              promo_badge_background_color={
                                categoryItem.color_theme
                                  ?.promo_badge_background_color
                              }
                              promo_badge_sub_text={
                                categoryItem.promo_badge_sub_text
                              }
                              custom_badge_style={`m-0 ${
                                currentStyle === '1 TOP 3 BELOW' ||
                                currentStyle === '1 TOP 2 BELOW'
                                  ? '!text-custom-2xs !truncate line-clamp-1'
                                  : ''
                              }`}
                              is_clearance_sale={true}
                            />
                          ) : (
                            <div className='h-6 w-full md:h-12'></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </ContentfulLink>
                )}
                {categoryItem.label && (
                  <h6
                    className={clsx(
                      'text-custom-base',
                      componentProperties?.style !== '1x4' ? 'mt-3' : 'mt-4',
                      componentProperties?.style === '3x4' && index > 2
                        ? 'mb-4'
                        : '',
                      componentProperties?.style === '3x2' && index > 0
                        ? 'mb-4'
                        : '',
                      componentProperties?.style === '1x4' ? 'mb-4' : '',
                      componentProperties?.style === '1x4' ? 'mb-4' : '',
                      componentProperties?.style === '2x2' ? 'mb-4' : '',
                      componentProperties?.style === '1x2' ? 'mb-4' : ''
                    )}
                  >
                    {categoryItem.label}
                  </h6>
                )}
              </div>
            ))}
        </div>
      )}
      {data.cta_button && (
        <button
          className={
            'mt-4 inline-flex h-10 w-full items-center justify-center gap-2.5 rounded-md px-6 py-3 text-center text-custom-sm text-white'
          }
          style={{
            backgroundColor: data?.color_theme?.cta_button_background_color,
            color: data?.color_theme?.cta_button_label_text_color,
          }}
        >
          {data.cta_button.label}
        </button>
      )}
    </div>
  );
}
