import Image from 'next/image';
import ReactPortal from 'components/react-portal';
import ProductPromotionModalContent from './products-content';
import { CloseIcon } from 'components/icon';
import clsx from 'clsx';
import { ProductItemType } from 'types/cms/product-carousel';
import { PromotionPopupType } from 'types/cms/promotion-popup';
import { ModalStyleType } from 'types/modal';

export default function MobileSaleModal({
  setOpenModal,
  popupType,
  products,
  promotionPopupData,
}: {
  setOpenModal: any;
  popupType: string;
  products: ProductItemType[];
  promotionPopupData: PromotionPopupType;
}) {
  const style: ModalStyleType = {};
  if (promotionPopupData.color_theme?.background_color) {
    style.backgroundColor = promotionPopupData.color_theme?.background_color;
  }
  if (promotionPopupData.background_image) {
    style.backgroundImage = `url('${promotionPopupData.background_image}')`;
  }
  const CloseButton = () => (
    <Image
      src={promotionPopupData.close_button}
      alt='Close'
      height={50}
      width={50}
      className='h-[50px] w-[50px] cursor-pointer'
    />
  );

  return (
    <ReactPortal wrapperId='product-promotion-modal'>
      <div className='fixed bottom-0 left-0 right-0 top-0 z-[99] h-screen w-full bg-white/50 backdrop-blur-lg'></div>
      <div
        className={clsx(
          'fixed bottom-0 left-0 right-0 top-0 z-[99] h-[90dvh] px-4 py-6'
        )}
        style={style}
      >
        {promotionPopupData.image && (
          <div className='-mt-6 mb-[14px]'>
            <Image
              src={promotionPopupData.image}
              alt=''
              width={0}
              height={0}
              sizes='100vw'
              className='h-[111px] w-auto'
            />
          </div>
        )}
        <div
          onClick={() => setOpenModal(false)}
          className='absolute top-[34px] flex items-center justify-center ltr:right-4 rtl:left-4'
        >
          {promotionPopupData.close_button ? (
            <CloseButton />
          ) : (
            <div className='flex h-[50px] w-[50px] items-center justify-center rounded-full bg-gray/50 backdrop-blur-sm'>
              <CloseIcon className='has-color fill-current text-white' />
            </div>
          )}
        </div>
        <ProductPromotionModalContent
          popupType={popupType}
          products={products}
          promotionPopupData={promotionPopupData}
        />
      </div>
    </ReactPortal>
  );
}
