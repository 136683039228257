import { extendTailwindMerge } from 'tailwind-merge';

// const fontSizeClassGroup = [
//     { 'text': [] }
// ]

const tailwindMerge = extendTailwindMerge({
  extend: {
    // We can group custom classes to their respective groups
    // Otherwise, tailwind-merge considers most of them as "color" classes by default
    // and it clashes with stuff like text-white
    classGroups: {
      // 'font-size': fontSizeClassGroup,
    },
  },
});

export default tailwindMerge;
