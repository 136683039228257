'use client';

import clsx from 'clsx';
import { Button } from 'components/buttons/button';
import BaseSwiper from 'components/swiper/base-swiper';
import Image from 'next/image';
import { SwiperSlide } from 'swiper/react';
import { StripeType } from 'types/cms/stripe';
import StripeCountdownRenderer from './stripe-countdown';
import { useCallback, useState } from 'react';
import { addDurationToDate } from 'hooks/use-countdown';
import ContentfulLink from 'components/contentful-link';
import { LanguageCodesType, RegionCodesType } from 'utils';

export default function Stripe({
  data,
  language,
  region,
}: {
  data: StripeType;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const [items, setItems] = useState(data?.items);
  const handleRemoveSlide = useCallback(
    (index: number) => {
      const itemsTemp = items.filter((item, itemIndex) => itemIndex !== index);
      setItems(itemsTemp);
    },
    [items]
  );
  return (
    <div className={`relative w-full ${data?.sticky ? '-mt-[1px]' : 'mt-0'}`}>
      <BaseSwiper
        swiperProps={{
          slidesPerView: 1,
          navigation: false,
          autoplay: data?.auto_scroll && {
            delay: (data?.speed ?? 0) * 1000,
            disableOnInteraction: false,
          },
          slideClass: 'swiper-slide',
          loop: true,
          spaceBetween: 0,
          fadelast: 'hide',
        }}
      >
        {items
          ?.filter(
            (promotionalStripeItem) =>
              promotionalStripeItem.promo_time_remaining !== '0h00m00'
          )
          .map((promotionalStripeItem, i) =>
            promotionalStripeItem.promo_time_remaining !== '0h00m00' ? (
              <SwiperSlide key={i}>
                <div
                  className={`flex h-10 w-full items-center justify-center ${
                    promotionalStripeItem.promotion_type === 'IMAGE'
                      ? 'px-0'
                      : 'px-4'
                  }`}
                  style={{
                    background:
                      promotionalStripeItem?.color_theme?.background_color,
                  }}
                >
                  {promotionalStripeItem.promotion_type === 'IMAGE' &&
                    promotionalStripeItem.image && (
                      <Image
                        src={promotionalStripeItem.image}
                        alt=''
                        fill
                        sizes='100%'
                        className='h-10 w-full lg:hidden'
                      />
                    )}
                  {promotionalStripeItem.promotion_type === 'IMAGE' &&
                    promotionalStripeItem.web_image && (
                      <Image
                        src={promotionalStripeItem.web_image}
                        alt=''
                        fill
                        sizes='100%'
                        className='hidden h-10 w-full lg:block'
                      />
                    )}
                  <div
                    className={clsx([
                      'relative z-10 flex h-full w-full items-center justify-center gap-3 text-center text-custom-sm text-white',
                    ])}
                    style={{
                      color:
                        promotionalStripeItem?.color_theme?.label_text_color,
                    }}
                  >
                    {(promotionalStripeItem.promotion_type === 'TEXT' ||
                      promotionalStripeItem.color_theme?.background_color) &&
                      promotionalStripeItem.image && (
                        <Image
                          src={promotionalStripeItem.image}
                          className='block lg:hidden'
                          width={20}
                          height={15}
                          alt={promotionalStripeItem.label || ''}
                          sizes='(max-width: 350px) 100vw, (max-width: 768px) 100vw, 100vw'
                        />
                      )}
                    {(promotionalStripeItem.promotion_type === 'TEXT' ||
                      promotionalStripeItem.color_theme?.background_color) &&
                      promotionalStripeItem.web_image && (
                        <Image
                          src={promotionalStripeItem.web_image}
                          className='hidden lg:block'
                          width={20}
                          height={15}
                          alt={promotionalStripeItem.label || ''}
                          sizes='(max-width: 350px) 100vw, (max-width: 768px) 100vw, 100vw'
                        />
                      )}
                    <div
                      className={clsx([
                        'flex items-center overflow-hidden',
                        promotionalStripeItem.sub_label
                          ? 'flex-col md:flex-row'
                          : 'flex-row',
                      ])}
                    >
                      {promotionalStripeItem.promotion_type !== 'COUNTDOWN' ? (
                        <div
                          className='whitespace-nowrap'
                          dangerouslySetInnerHTML={{
                            __html: promotionalStripeItem?.label ?? '',
                          }}
                        />
                      ) : (
                        <StripeCountdownRenderer
                          onCountdownEnds={() => handleRemoveSlide(i)}
                          content={promotionalStripeItem?.label ?? ''}
                          date={addDurationToDate(
                            promotionalStripeItem.promo_time_remaining ?? ''
                          )}
                        />
                      )}
                      <div className='flex items-center ltr:ml-1 rtl:mr-1'>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: promotionalStripeItem?.sub_label ?? '',
                          }}
                        />

                        {promotionalStripeItem?.cta_button?.label &&
                          (promotionalStripeItem.color_theme
                            ?.cta_button_background_color == '' ||
                            promotionalStripeItem.cta_button.button_type ===
                              'Hyperlink') && (
                            <Button
                              className={clsx([
                                '!w-auto whitespace-nowrap !p-2 !text-custom-xs font-semibold text-white ltr:ml-2 ltr:md:ml-3 rtl:mr-2 rtl:md:mr-3',
                                '!p-0 !text-custom-sm underline',
                              ])}
                              style={{
                                backgroundColor: 'transparent',
                                color:
                                  promotionalStripeItem.color_theme
                                    ?.cta_button_label_text_color,
                              }}
                            >
                              <ContentfulLink
                                item={promotionalStripeItem}
                                language={language}
                                region={region}
                              >
                                {promotionalStripeItem.cta_button?.label}
                              </ContentfulLink>
                            </Button>
                          )}
                      </div>
                    </div>
                    {promotionalStripeItem?.cta_button?.label &&
                      promotionalStripeItem.cta_button.button_type !==
                        'Hyperlink' && (
                        <Button
                          className={clsx([
                            'max-h-[29px] max-w-[73px] whitespace-nowrap !p-2 !text-custom-xs font-semibold text-white ltr:ml-2 ltr:md:ml-3 rtl:mr-2 rtl:md:mr-3',
                          ])}
                          style={{
                            backgroundColor:
                              promotionalStripeItem.color_theme
                                ?.cta_button_background_color,
                            color:
                              promotionalStripeItem.color_theme
                                ?.cta_button_label_text_color,
                          }}
                        >
                          <ContentfulLink
                            item={promotionalStripeItem}
                            className=''
                            language={language}
                            region={region}
                          >
                            <h5 className='overflow-hidden text-clip'>
                              {promotionalStripeItem.cta_button?.label}
                            </h5>
                          </ContentfulLink>
                        </Button>
                      )}
                  </div>
                </div>
              </SwiperSlide>
            ) : null
          )}
      </BaseSwiper>
    </div>
  );
}
